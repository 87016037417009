import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { EnterpriseService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import IncidentSummary from 'components/IncidentSummary';
import FormNavigation from 'components/FormNavigation';
import { Grid, SubHeader } from 'layout';
import { useDebounce } from 'hooks';
import { catchError } from 'utils';
import { fetchLocation } from 'store/location';
import IncidentDone from 'routes/Incident/IncidentDone';
import IncidentContext from '../Incident/context';
import IntakeForm from './Form';
import Footer from './Footer';

const IntakeRoute = ({ readOnly, children }) => {
    const { update, patch, done } = useContext(IncidentContext);

    const dispatch = useDispatch();
    const incident = useSelector(state => state.incident);
    const location = useSelector(state => state.location);
    const [categories, setCategories] = useState();
    const [directives, setDirectives] = useState([]);
    const [debouncedFields, setDebouncedFields] = useDebounce(undefined, 4000);
    const [formLoading, setFormLoading] = useState(false);

    const onFinish = values => update(values)
        .then(() => setFormLoading(false));

    const dispatchPatchIncident = useCallback((data) => {
        patch(data)
            .finally(() => {
                setFormLoading(false);
            });
    }, [patch]);

    useEffect(() => {
        if (debouncedFields) {
            dispatchPatchIncident(debouncedFields);
        }
    }, [debouncedFields, dispatchPatchIncident]);

    const onValuesChange = (changed, all) => {
        if (changed.locationId) {
            dispatch(fetchLocation({ id: changed.locationId }));
        } else if (changed.episodeTypeId) {
            setFormLoading(true);
            dispatchPatchIncident({
                episodeTypeId: changed.episodeTypeId,
                locationId: location.id
            });
        } else if (changed.fields?.employer_location_state) {
            dispatchPatchIncident(changed);
        } else if (changed.fields || changed.directives) {
            setDebouncedFields({ fields: all.fields, directives: all.directives });
        }
    };
    
    useEffect(() => {
        if (incident.locationId) {
            setFormLoading(true);
            EnterpriseService.questions.list({
                id: incident.locationId,
                episodeTypeId: incident.episodeTypeId
            })
                .then(setCategories)
                .then(() => EnterpriseService.directives.list({
                    locationId: incident.locationId,
                    episodeTypeId: incident.episodeTypeId,
                }))
                .then(setDirectives)
                .catch(catchError)
                .finally(() => setFormLoading(false));
        }
    }, [incident.locationId, incident.episodeTypeId]);

    const disabled = incident.$loading || formLoading;

    const footerDefault = (
        <Footer
            onFinish={onFinish}
            disabled={disabled}
        />
    );

    return (
        <>
            <SubHeader>
                <FormNavigation
                    categories={[
                        { title: 'General', name: 'general' },
                        ...(categories || [])
                    ]}
                />
            </SubHeader>
            <Grid className="grid-intake">
                {done ? <IncidentDone />
                    : (
                        <IntakeForm
                            onValuesChange={onValuesChange}
                            categories={categories}
                            directives={directives}
                            loading={formLoading}
                            readOnly={readOnly}
                        >
                            {children || footerDefault}
                        </IntakeForm>
                    )}
                <IncidentSummary
                    noteType={EnterpriseService.notes.INTAKE_TYPE}
                />
            </Grid>
        </>
    );
};

IntakeRoute.propTypes = {
    readOnly: PropTypes.bool,
    children: PropTypes.any
};

IntakeRoute.defaultProps = {
    readOnly: false,
    children: undefined
};

export default IntakeRoute;
