import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Drawer, Result } from 'library';
import { EnterpriseService } from 'services';
import { useDeepCompareEffect } from 'hooks';
import { IncidentProps } from './DuplicateConfig';
import Duplicate from './Duplicate';
import Footer from './Footer';

const StartDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD');
const EndDate = dayjs().format('YYYY-MM-DD');
const DateRange = [StartDate, EndDate];

const IncidentDuplicates = ({ filters, filterValues, incidentTypeId, setSearching }) => {
    const [open, setOpen] = useState(false);
    const [incidents, setIncidents] = useState([]);

    const findMatches = useCallback((_filters) => {
        const transformIncidents = data => data.map((item) => {
            const props = {};
            let hasMatches = 0;
            IncidentProps.forEach(({ filter, key, render }) => {
                const value = item[key];
                if (filter) {
                    const hasValues = !!value && !!filterValues[filter];
                    if (hasValues) {
                        const hasMatch = value.toString().toLowerCase()
                            === filterValues[filter].toString().toLowerCase();
                        if (hasMatch) {
                            props[`$${key}`] = 'match';
                            hasMatches += 1;
                        }
                    }
                }
                props[key] = render ? render(item) : value;
            });
            return {
                ...item,
                ...props,
                $fullMatch: hasMatches === 5
            };
        });
        setSearching(true);
        EnterpriseService.incidents.search({
            ..._filters,
            createdDate: DateRange,
            extend: IncidentProps.map(item => item.key),
        })
            .then(({ data }) => {
                if (data.length) {
                    setIncidents(transformIncidents(data));
                    setOpen(true);
                }
            })
            .finally(() => setSearching(false));
    }, [filterValues, setSearching]);

    useDeepCompareEffect(() => {
        if (filters) {
            findMatches(filters);
        }
    }, [filters]);

    const hasFullMatch = incidents.find(item => !!item.$fullMatch);

    return (
        <Drawer
            title={(
                <Result
                    status={hasFullMatch ? 'warning' : undefined}
                    title={hasFullMatch ? 'Duplicate Incident Detected' : 'Possible Duplicate Incident(s)'}
                />
            )}
            open={open}
            closable={false}
            footer={(
                <Footer
                    incidents={incidents}
                    setOpen={setOpen}
                />
            )}
        >
            {incidents.map(item => (
                <Duplicate
                    key={item.id}
                    item={item}
                    setOpen={setOpen}
                    incidents={incidents}
                    incidentTypeId={incidentTypeId}
                />
            ))}
            
        </Drawer>
    );
};

IncidentDuplicates.propTypes = {
    filters: PropTypes.object,
    filterValues: PropTypes.object.isRequired,
    incidentTypeId: PropTypes.number,
    setSearching: PropTypes.func
};

IncidentDuplicates.defaultProps = {
    filters: undefined,
    incidentTypeId: undefined,
    setSearching: () => {}
};

export default IncidentDuplicates;
