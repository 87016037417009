import GenesysAuthService from '../../genesys/auth.service';

const ExpiredToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZW5zcGlyaS5sb2NhbC5vc2kvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MTI4NDkxMDAsImV4cCI6MTcxMjg1MjcwMCwibmJmIjoxNzEyODQ5MTAwLCJqdGkiOiJEVWhveGhaUlA3TU5ZRlVXIiwic3ViIjo5MzIsInBydiI6ImYwZmFmMzA2YTE5NDVhNTBmYzlmYTllMjU0Nzg2ODNiMDc0NDE1MTcifQ.GUCkDVgOjGhpwRZgtpAHuI_z-hxSfHZyrWgluitJTs4';

class EnterpriseAuthService {
    #esToken;

    api;

    constructor(api) {
        this.api = api;
    }

    deactivateToken() {
        this.#esToken = ExpiredToken;
    }

    refreshToken() {
        return this.getAccessToken('/api/auth/refresh');
    }

    async getAccessToken(url, data = {}) {
        const res = await this.api.post(url, data);
        return res?.data?.token;
    }

    async token(renew) {
        if (!this.#esToken || renew) {
            const gcToken = GenesysAuthService.getAccessToken();
            if (gcToken) {
                this.#esToken = await this.getAccessToken('/api/auth/login', { gcToken });
            }
        }
        if (!this.#esToken) {
            await GenesysAuthService.signIn();
            const gcToken = GenesysAuthService.getAccessToken();
            if (gcToken) {
                this.#esToken = await this.getAccessToken('/api/auth/login', { gcToken });
            }
        }
        
        return this.#esToken;
    }
}

export default EnterpriseAuthService; 
