import { toQueryParams } from 'utils';
import api from '../api';
import transformList from './transform.list';

class EnterpriseDistributionService {
    static async list(incidentId, filters) {
        const params = toQueryParams({
            perPage: 50,
            page: 1,
            ...filters
           // orderDirection: 'DESC'
        });

        return api.get(`/api/v2/incidents/${incidentId}/distributions?${params}`)
            .then(({ data }) => transformList(data));
    }

    static async download(distributionId) {
        return api.get(`/api/v2/distributions/${distributionId}/download`);
    }

    static async requeue(distributionId, filters) {
        const params = toQueryParams({
            ...filters
        });
        return api.post(`/api/v2/distributions/${distributionId}?${params}`);
    }
}

export default EnterpriseDistributionService;
