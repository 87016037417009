export default {
    id: '8e508b1a-1b9d-4fe5-822b-0cc5480d5c1d',
    startTime: '2024-05-14T15:35:05.788Z',
    address: 'tel:+19452533887',
    participants: [
        {
            id: 'fe072d3d-8d68-4244-bbab-479f36fc4b0c',
            startTime: '2024-05-14T15:35:05.788Z',
            connectedTime: '2024-05-14T15:35:06.050Z',
            name: '8014443844 8014443844',
            externalContactId: '2aa857e4-27f5-408d-b98f-6499ab850ff7',
            queueId: 'b3aacae1-f65b-4098-9c4a-3432264a7a2d',
            queueName: 'Intake',
            purpose: 'customer',
            participantType: 'External',
            address: 'tel:+18014443844',
            ani: 'tel:+18014443844',
            aniName: '8014443844 8014443844',
            dnis: 'tel:+19452533887',
            wrapupRequired: false,
            mediaRoles: [
                'full'
            ],
            attributes: {
                'Option Skill': 'Auto',
                isUrgent: 'false',
                'SP 1': 'Auto|10',
                'Offer Callback': 'true',
                'SP 2': 'Liability|10',
                'Screen Pop URL': '',
                'SP 9': '|',
                SelectedState: '0',
                'SP 7': '|',
                'SP 8': '|',
                'SP 5': '|',
                'SP 6': '|',
                Skills: 'Auto',
                IncidentNumber: 'IncidentNumber2',
                'SP 3': 'Injury|10',
                'SP 4': '|',
                'Client Priority': '0',
                SpecialGreeting: '',
                'In Queue Prompt 1': 'CompanyNurseOption1CallBackSpanish52918',
                'In Queue Prompt 2': '',
                'TD 7': '|',
                'TD 6': '|',
                'TD 5': '|',
                'Last Task': 'Queue Transfer',
                'TD 4': '|',
                // SearchCode: 'V026',
                'TD 3': 'queue|Intake',
                'TD 2': 'queue|WC',
                'TD 1': 'queue|Intake',
                SelectedLanguage: 'English',
                'Agent Text': 'This Call is from ABC Company',
                'Total Priority': '10',
                'TD 9': '|',
                isVIP: '',
                'TD 8': '|',
                'Option  Priority': '10',
                scriptId: 'cfd4afcf-6e07-4ab3-a8bf-11618a2679a2',
                'TD Closed': 'queue|Intake',
                ScreenPopName: 'Universal Script ',
                'SP Closed': '|'
            },
            calls: [
                {
                    state: 'connected',
                    initialState: 'offering',
                    id: '5738b5e2-3070-473d-9127-402049e4797b',
                    direction: 'inbound',
                    recording: true,
                    recordingState: 'paused',
                    muted: false,
                    confined: false,
                    held: false,
                    securePause: false,
                    segments: [
                        {
                            startTime: '2024-05-14T15:35:05.788Z',
                            endTime: '2024-05-14T15:35:06.050Z',
                            type: 'System',
                            howEnded: 'Interact'
                        },
                        {
                            startTime: '2024-05-14T15:35:06.050Z',
                            type: 'Interact'
                        }
                    ],
                    connectedTime: '2024-05-14T15:35:06.050Z',
                    disconnectReasons: [],
                    provider: 'Edge',
                    self: {
                        name: '8014443844 8014443844',
                        nameRaw: '8014443844 8014443844',
                        addressNormalized: 'tel:+18014443844',
                        addressRaw: 'sip:+18014443844@companynursedev.pstn.twilio.com:5060',
                        addressDisplayable: 'unavailable'
                    },
                    other: {
                        name: 'Plano TX',
                        nameRaw: '',
                        addressNormalized: 'tel:+19452533887',
                        addressRaw: 'sip:+19452533887@10.87.23.130:8140;transport=tcp',
                        addressDisplayable: 'unavailable'
                    },
                    afterCallWork: {},
                    afterCallWorkRequired: false
                }
            ],
            callbacks: [],
            chats: [],
            cobrowsesessions: [],
            emails: [],
            messages: [],
            screenshares: [],
            socialExpressions: [],
            videos: []
        },
        {
            id: '41c1f85a-3ca4-4916-bfc7-411f38220155',
            startTime: '2024-05-14T15:35:05.868Z',
            endTime: '2024-05-14T15:35:14.316Z',
            connectedTime: '2024-05-14T15:35:05.962Z',
            name: 'Universal Call Flow 42 - 4-18-22',
            purpose: 'ivr',
            participantType: 'IVR',
            address: 'sip:b461e6c5-0f5c-4500-af4b-b403fb6600e2@127.0.0.1;language=en-US;user=ivr',
            ani: 'tel:+18014443844',
            aniName: '8014443844 8014443844',
            dnis: 'sip:b461e6c5-0f5c-4500-af4b-b403fb6600e2@127.0.0.1;language=en-US;user=ivr',
            wrapupRequired: false,
            mediaRoles: [],
            attributes: {},
            calls: [
                {
                    state: 'terminated',
                    initialState: 'offering',
                    id: '340fe0fa-029d-4a0d-b489-9cc2fab89f73',
                    direction: 'inbound',
                    recording: false,
                    recordingState: 'none',
                    muted: false,
                    confined: false,
                    held: false,
                    securePause: false,
                    segments: [
                        {
                            startTime: '2024-05-14T15:35:05.868Z',
                            endTime: '2024-05-14T15:35:05.962Z',
                            type: 'System'
                        },
                        {
                            startTime: '2024-05-14T15:35:05.962Z',
                            endTime: '2024-05-14T15:35:14.316Z',
                            type: 'IVR',
                            howEnded: 'Disconnect',
                            disconnectType: 'transfer'
                        }
                    ],
                    disconnectType: 'transfer',
                    connectedTime: '2024-05-14T15:35:05.962Z',
                    disconnectedTime: '2024-05-14T15:35:14.316Z',
                    disconnectReasons: [],
                    provider: 'Edge',
                    peerId: '5738b5e2-3070-473d-9127-402049e4797b',
                    self: {
                        name: 'Plano TX',
                        nameRaw: 'Plano TX',
                        addressNormalized: 'sip:b461e6c5-0f5c-4500-af4b-b403fb6600e2@127.0.0.1;language=en-US;user=ivr',
                        addressRaw: 'sip:b461e6c5-0f5c-4500-af4b-b403fb6600e2@127.0.0.1;language=en-US;user=ivr',
                        addressDisplayable: 'unavailable'
                    },
                    other: {
                        name: '8014443844 8014443844',
                        nameRaw: '8014443844 8014443844',
                        addressNormalized: 'tel:+18014443844',
                        addressRaw: 'sip:+18014443844@10.87.23.130;user=phone',
                        addressDisplayable: 'unavailable'
                    },
                    afterCallWork: {},
                    afterCallWorkRequired: false
                }
            ],
            callbacks: [],
            chats: [],
            cobrowsesessions: [],
            emails: [],
            messages: [],
            screenshares: [],
            socialExpressions: [],
            videos: []
        },
        {
            id: 'c210f46e-29d0-459c-9e9a-4b1f9b82a14a',
            startTime: '2024-05-14T15:35:14.318Z',
            connectedTime: '2024-05-14T15:35:14.412Z',
            name: 'Intake',
            queueId: 'b3aacae1-f65b-4098-9c4a-3432264a7a2d',
            queueName: 'Intake',
            purpose: 'acd',
            participantType: 'ACD',
            address: 'sip:b3aacae1-f65b-4098-9c4a-3432264a7a2d@127.0.0.1;language=en-US;src=https://edge-proxy.usw2.pure.cloud/connector/v1/organizations/73df59e9-2038-4637-8f01-a2a3ac490723/defaults/ivr/871ad368-dcca-441f-861c-a9b92991dbd2/defaultentrypoint.vxml;user=acd',
            ani: 'tel:+18014443844',
            aniName: '8014443844 8014443844',
            dnis: 'sip:b3aacae1-f65b-4098-9c4a-3432264a7a2d@127.0.0.1;language=en-US;src=https://edge-proxy.usw2.pure.cloud/connector/v1/organizations/73df59e9-2038-4637-8f01-a2a3ac490723/defaults/ivr/871ad368-dcca-441f-861c-a9b92991dbd2/defaultentrypoint.vxml;user=acd',
            wrapupRequired: false,
            mediaRoles: [],
            conversationRoutingData: {
                queue: {
                    id: 'b3aacae1-f65b-4098-9c4a-3432264a7a2d',
                    selfUri: '/api/v2/routing/queues/b3aacae1-f65b-4098-9c4a-3432264a7a2d'
                },
                priority: 10,
                skills: [
                    {
                        id: '4e0eb175-b3ec-4ee8-87c4-9afa1c494419',
                        selfUri: '/api/v2/routing/skills/4e0eb175-b3ec-4ee8-87c4-9afa1c494419'
                    }
                ],
                scoredAgents: [],
                label: '631f0939-be32-495a-baf9-970abb039192'
            },
            attributes: {
                'ivr.Priority': '10',
                'ivr.Skills': '4e0eb175-b3ec-4ee8-87c4-9afa1c494419'
            },
            calls: [
                {
                    state: 'connected',
                    initialState: 'offering',
                    id: 'dc1751a1-10c5-45f8-84b6-91981b31c767',
                    direction: 'inbound',
                    recording: false,
                    recordingState: 'none',
                    muted: false,
                    confined: false,
                    held: false,
                    securePause: false,
                    segments: [
                        {
                            startTime: '2024-05-14T15:35:14.318Z',
                            endTime: '2024-05-14T15:35:14.412Z',
                            type: 'Delay'
                        },
                        {
                            startTime: '2024-05-14T15:35:14.412Z',
                            type: 'Interact'
                        }
                    ],
                    connectedTime: '2024-05-14T15:35:14.412Z',
                    disconnectReasons: [],
                    provider: 'Edge',
                    peerId: '5738b5e2-3070-473d-9127-402049e4797b',
                    self: {
                        name: 'Intake',
                        nameRaw: 'Intake',
                        addressNormalized: 'sip:b3aacae1-f65b-4098-9c4a-3432264a7a2d@127.0.0.1;language=en-US;src=https://edge-proxy.usw2.pure.cloud/connector/v1/organizations/73df59e9-2038-4637-8f01-a2a3ac490723/defaults/ivr/871ad368-dcca-441f-861c-a9b92991dbd2/defaultentrypoint.vxml;user=acd',
                        addressRaw: 'sip:b3aacae1-f65b-4098-9c4a-3432264a7a2d@127.0.0.1;language=en-US;src=https://edge-proxy.usw2.pure.cloud/connector/v1/organizations/73df59e9-2038-4637-8f01-a2a3ac490723/defaults/ivr/871ad368-dcca-441f-861c-a9b92991dbd2/defaultentrypoint.vxml;user=acd',
                        addressDisplayable: 'unavailable'
                    },
                    other: {
                        name: '8014443844 8014443844',
                        nameRaw: '8014443844 8014443844',
                        addressNormalized: 'tel:+18014443844',
                        addressRaw: 'sip:+18014443844@10.87.23.130;user=phone',
                        addressDisplayable: 'unavailable'
                    },
                    afterCallWork: {},
                    afterCallWorkRequired: false
                }
            ],
            callbacks: [],
            chats: [],
            cobrowsesessions: [],
            emails: [],
            messages: [],
            screenshares: [],
            socialExpressions: [],
            videos: []
        },
        {
            id: '6a3c42ad-ce2b-4c5e-9ea0-7987eff4e9c7',
            startTime: '2024-05-14T15:35:14.835Z',
            name: 'OSI Devs',
            userUri: '/api/v2/users/d4f0d206-07e6-4c1d-811b-b794ff12947e',
            userId: 'd4f0d206-07e6-4c1d-811b-b794ff12947e',
            queueId: 'b3aacae1-f65b-4098-9c4a-3432264a7a2d',
            queueName: 'Intake',
            purpose: 'agent',
            participantType: 'Internal',
            address: 'sip:60c29b9c586ae219da1dee64+cn-dev.orgspan.com;tgrp=c4c0f6cd-8201-4a14-a2aa-e9e0c00cf8d1;trunk-context=cn-dev@localhost',
            ani: 'sip:+18014443844@10.87.23.130;user=phone',
            aniName: '8014443844 8014443844',
            dnis: 'sip:60c29b9c586ae219da1dee64+cn-dev.orgspan.com;tgrp=c4c0f6cd-8201-4a14-a2aa-e9e0c00cf8d1;trunk-context=cn-dev@localhost',
            wrapupRequired: false,
            wrapupPrompt: 'optional',
            mediaRoles: [
                'full'
            ],
            alertingTimeoutMs: 8000,
            attributes: {},
            calls: [
                {
                    state: 'alerting',
                    initialState: 'alerting',
                    id: '466ef0fe-65cf-4889-b143-5bc0051769ec',
                    direction: 'inbound',
                    recording: false,
                    recordingState: 'none',
                    muted: false,
                    confined: false,
                    held: false,
                    securePause: false,
                    segments: [
                        {
                            startTime: '2024-05-14T15:35:14.835Z',
                            type: 'Alert'
                        }
                    ],
                    startAlertingTime: '2024-05-14T15:35:15.041Z',
                    disconnectReasons: [],
                    provider: 'Edge',
                    peerId: '5738b5e2-3070-473d-9127-402049e4797b',
                    self: {
                        nameRaw: 'devoops@objectsystems.com',
                        addressNormalized: 'sip:60c29b9c586ae219da1dee64+cn-dev.orgspan.com;tgrp=c4c0f6cd-8201-4a14-a2aa-e9e0c00cf8d1;trunk-context=cn-dev@localhost',
                        addressRaw: 'sip:60c29b9c586ae219da1dee64+cn-dev.orgspan.com@10.87.23.130;language=en-US;user=station',
                        addressDisplayable: 'unavailable'
                    },
                    other: {
                        name: '8014443844 8014443844',
                        nameRaw: '8014443844 8014443844',
                        addressNormalized: 'sip:+18014443844@10.87.23.130;user=phone',
                        addressRaw: 'sip:+18014443844@10.87.23.130;user=phone',
                        addressDisplayable: 'unavailable'
                    },
                    afterCallWork: {},
                    afterCallWorkRequired: false,
                    queueMediaSettings: {
                        alertingTimeoutSeconds: 8,
                        autoAnswerAlertToneSeconds: 0,
                        manualAnswerAlertToneSeconds: 0
                    }
                }
            ],
            callbacks: [],
            chats: [],
            cobrowsesessions: [],
            emails: [],
            messages: [],
            screenshares: [],
            socialExpressions: [],
            videos: []
        }
    ],
    recordingState: 'PAUSED',
    divisions: [
        {
            division: {
                id: '1f21ee43-4877-4a2f-9fd0-a20031b4e99f',
                selfUri: '/api/v2/authorization/divisions/1f21ee43-4877-4a2f-9fd0-a20031b4e99f'
            },
            entities: [
                {
                    id: '2437a7d5-77b5-4a39-aa4e-c7d3dca55b45',
                    selfUri: '/api/v2/flows/2437a7d5-77b5-4a39-aa4e-c7d3dca55b45'
                },
                {
                    id: 'b3aacae1-f65b-4098-9c4a-3432264a7a2d',
                    selfUri: '/api/v2/routing/queues/b3aacae1-f65b-4098-9c4a-3432264a7a2d'
                },
                {
                    id: '5c81bc03-d905-4d1b-87c1-6194a75066ce',
                    selfUri: '/api/v2/flows/5c81bc03-d905-4d1b-87c1-6194a75066ce'
                },
                {
                    id: 'd4f0d206-07e6-4c1d-811b-b794ff12947e',
                    selfUri: '/api/v2/users/d4f0d206-07e6-4c1d-811b-b794ff12947e'
                }
            ]
        }
    ],
    recentTransfers: [
        {
            id: '43d6de5b-5d58-41b7-8c03-9e66e48c981d',
            state: 'Active',
            dateIssued: '2024-05-14T15:35:14.761Z',
            initiator: {},
            modifiedBy: {},
            destination: {
                userId: 'd4f0d206-07e6-4c1d-811b-b794ff12947e',
                address: 'sip:devoops%40objectsystems.com@localhost'
            },
            transferType: 'Attended'
        }
    ],
    securePause: false,
    utilizationLabelId: '631f0939-be32-495a-baf9-970abb039192',
    selfUri: '/api/v2/conversations/8e508b1a-1b9d-4fe5-822b-0cc5480d5c1d'
};
