import { getUrlQueryParams } from 'utils';
import api from '../api';
import transformList from './transformer.list';
import transformUpdate from './transformer.update';
import transformCreate from './transformer.create';
import transformGet from './transformer.get';

class EnterpriseIncidentService {
    static async get(incidentId) {
        const { data } = await api.get(`/api/v2/incidents/${incidentId}`);
        return transformGet(data);
    }

    static async list(args) {
        return api.get(`/api/v2/incidents?${getUrlQueryParams(args)}`)
            .then(transformList);
    }

    static search(filters) {
        return api.post('/api/v2/incidents/searches', filters)
            .then(transformList);
    }

    static async find(filters) {
        return this.list({
            page: 1,
            perPage: 1,
            filters
        })
            .then((result) => {
                if (result?.data?.length) {
                    return result.data[0];
                }
                return undefined;
            });
    }

    static async create(values) {
        const { data } = await api.post('/api/v2/incidents', transformCreate(values));
        return transformGet(data);
    }

    static async update(id, values) {
        const postData = transformUpdate(values);
        const { data } = await api.put(`/api/v2/incidents/${id}`, postData);
        return transformGet(data);
    }

    static async clone(id, values) {
        const { data } = await api.post(`/api/v2/incidents/${id}`, values);
        return transformGet(data);
    }
}

export default EnterpriseIncidentService;
