import './IntakeDone.scss';
import { useEffect, useContext, useCallback } from 'react';
import { Button } from 'library';
import { useNavigate } from 'react-router-dom';
import { ImageSuccess } from 'assets';
import IncidentContext from '../context';

const IncidentDone = () => {
    const { cleanUp } = useContext(IncidentContext);
    const navigate = useNavigate();

    const goHomeAndCleanUp = useCallback(() => {
        navigate('/');
        cleanUp();
    }, [cleanUp, navigate]);

    useEffect(() => {
        const timer = setTimeout(() => {
            goHomeAndCleanUp();
        }, 7000);
        return () => timer && clearTimeout(timer);
    }, [goHomeAndCleanUp]);

    return (
        <div className="incident-done">
            <div>
                <img src={ImageSuccess} alt="Completed Successfully" />
                <h1>Intake Completed Successfully</h1>
                <Button
                    block
                    size="large"
                    type="primary navigate"
                    onClick={goHomeAndCleanUp}
                >
                    Get Another Call
                </Button>
            </div>
        </div>
    );
};

export default IncidentDone;
