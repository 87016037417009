import './ButtonExpand.scss';
import PropTypes from 'prop-types';

const ButtonExpand = ({ setExpanded, expanded, expandalbleKeys }) => {
    if (!expandalbleKeys.length) {
        return null;
    }
    return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
            onClick={() => setExpanded(expanded.length ? [] : expandalbleKeys)}
            type="button"
            className={`ant-table-row-expand-icon ant-table-row-expand-icon-${expanded.length ? 'expanded' : 'collapsed'}`}
        />
    );
};

ButtonExpand.propTypes = {
    setExpanded: PropTypes.func.isRequired,
    expanded: PropTypes.array.isRequired,
    expandalbleKeys: PropTypes.array.isRequired
};

export { ButtonExpand };
