import React, { useContext, useRef, useEffect, useState } from 'react';
import { Table } from 'library';
import { useSelector } from 'react-redux';
import { EnterpriseService } from 'services';
import getColumns from './config';
import FacilityContext from '../../context';

const InternalFacilitiesTab = () => {
    const dataTable = useRef();
    const [expanded, setExpanded] = useState([]);
    const { locationId } = useSelector(state => state.incident);
    const { facility, onFacilityChange, preferredIds, address } = useContext(FacilityContext);

    const fetchAction = args => EnterpriseService.facilities.list({
        ...args,
        filters: {
            ...args.filters,
            distanceFromAddress: address,
            locationId
        },
    });

    useEffect(() => {
        const { fetchParams } = dataTable.current;
        dataTable.current.fetch({ ...fetchParams });
    }, [address]);

    const toggleExpanded = (id) => {
        if (expanded.includes(id)) {
            setExpanded(expanded.filter(item => item !== id));
        } else setExpanded([...expanded, id]);
    };

    const onRow = item => ({
        onClick: item.children?.length
            ? () => toggleExpanded(item.id)
            : () => onFacilityChange(item)
    });

    const columns = getColumns({
        preferredIds, locationId, setExpanded, expanded
    });

    return (
        <Table
            ref={dataTable}
            defaultPageSize={50}
            pageSizeOptions={[15, 50, 100, 200]}
            name="InternalFacilitiesTable"
            className="internal-facilities-table"
            rowKey="id"
            columns={columns}            
            fetchAction={address ? fetchAction : () => Promise.resolve({ data: [] })}
            scroll={{ x: 'max-content', y: 500 }}
            loadOnInit={false}
            rowSelection={{
                columnWidth: 50,
                type: 'radio',
                onChange: (_, [item]) => onFacilityChange(item),
                selectedRowKeys: facility?.id ? [facility.id] : [],
                getCheckboxProps: item => ({
                    disabled: item.children?.length > 0,
                    style: item.children?.length > 0 ? { display: 'none' } : null
                }),
                
            }}
            expandable={{ 
                expandedRowKeys: expanded,
                onExpandedRowsChange: setExpanded
            }}
            onRow={onRow}
            rowClassName={item => (item.facilityProviderContactId ? 'is-contact' : '')}
        />
    );
};

export default InternalFacilitiesTab;
