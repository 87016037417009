import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { GenesysChannelService, GenesysApp } from 'services';
import { clear as clearConversation, fetchConversation } from 'store/call';
import { clear as clearLocation } from 'store/location';
import { clear as clearIncident } from 'store/incident';
import { signIn } from 'store/me';
import { Layout } from 'layout';
import { Spin } from 'library';
import RouteCallerId from './CallerId';
import RouteIncident from './Incident';
import RouteIncidentIntake from './IncidentIntake';
import RouteIncidentNoTriage from './IncidentNoTriage';
import RouteIncidentNoTriageSummary from './IncidentNoTriageSummary';
import NotFound from './NotFound';
import RouteTest from './Test';

import IncidentPreviewFooter from './IncidentPreview/Footer';
import IncidentReportOnlySummaryFooter from './IncidentReportOnlySummary/Footer';

const App = () => {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const call = useSelector(state => state.call);
    const { pathname } = useLocation();

    const onCall = useCallback((event) => {
        if (pathname === '/') {
            if (event.id && !call.isConnected) {
                dispatch(clearLocation());
                dispatch(clearIncident());
                dispatch(clearConversation());
                dispatch(fetchConversation(event.id));
            }
        }
    }, [pathname, dispatch, call.isConnected]);

    useEffect(() => {
        if (me?.id) {
            GenesysChannelService.subscribeToCalls(me.id, onCall);
        }
    }, [me?.id, onCall]);

    useEffect(() => {
        dispatch(signIn())
            .then(() => {
                GenesysApp.onFocus(() => {
                    // eslint-disable-next-line no-console                    
                    console.log('LAX is on focus!');
                    GenesysApp.alert('LAX is on focus! 😊');
                });
            });
    }, [dispatch]);

    return (
        <React.Suspense fallback={<Spin className="center" />}>
            <Routes>
                <Route path="/" element={<Layout me={me} />}>
                    <Route index element={<RouteCallerId />} />
                    <Route path="incidents/:id/*" element={<RouteIncident />}>
                        <Route path="intake/:nextId?" element={<RouteIncidentIntake />} />
                        <Route path="no-triage" element={<RouteIncidentNoTriage />} />
                        <Route path="no-triage-summary" element={<RouteIncidentNoTriageSummary />} />
                        <Route
                            path="preview"
                            element={((
                                <RouteIncidentIntake readOnly>
                                    <IncidentPreviewFooter />
                                </RouteIncidentIntake>
                            ))}
                        />
                        <Route
                            path="report-only-summary/:nextId?"
                            element={(
                                <RouteIncidentIntake readOnly>
                                    <IncidentReportOnlySummaryFooter />
                                </RouteIncidentIntake>
                            )}
                        />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="playground" element={<RouteTest />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </React.Suspense>
    );
};

export default App;
