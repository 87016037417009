import './FormItemExtended.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import { Rules } from 'utils';
import FormField from './Field';
import { calculateShowIf } from './helpers';
import ButtonCopy from './ButtonCopy';

const FormItemExtended = ({
    name, prefix, label, required,
    type, showIf, copy, disabled, ...rest
}) => {
    const rules = [];
    if (required) {
        rules.push(Rules.requiredExt);
    }
    if (rest.estimated) {
        rules.push(Rules.estimated);
    }
    if (type === 'DATE') {
        rules.push(Rules.date);
    }
    
    const className = `
        form-item-${type} 
        ${required ? 'required' : ''} 
        ${showIf ? 'show-if' : ''}
    `;

    const fullName = prefix ? [prefix, name] : name;

    const FullField = (
        <>
            <Form.Item
                className={`form-item ${className} form-item-${prefix}-${name}`}
                name={fullName}
                label={label}
                rules={rules}
            >
                <FormField
                    required={required}
                    type={type}
                    disabled={disabled}
                    name={name}
                    {...rest}
                />
            </Form.Item>
            {copy && (
                <ButtonCopy
                    config={copy}
                    disabled={disabled}
                />
            )}
        </>
    );

    if (showIf) {
        return (
            <Form.Item
                noStyle
                shouldUpdate
            >
                {({ getFieldValue }) => {
                    const visible = calculateShowIf(showIf, getFieldValue);
                    return visible ? FullField : null;
                }}
            </Form.Item>
        );
    }

    return FullField;
};

FormItemExtended.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    estimated: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    showIf: PropTypes.object, // @TODONEXT shape it
    copy: PropTypes.object, // @TODONEXT shape it
    prefix: PropTypes.string
};

FormItemExtended.defaultProps = {
    required: false,
    estimated: false,
    showIf: undefined,
    copy: undefined,
    disabled: false,
    type: 'TEXT',
    prefix: undefined
};

export { FormItemExtended };
