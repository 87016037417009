import dayjs from 'dayjs';

export const Rules = {
    required: { required: true, message: '' },
    requiredExt: () => ({
        validator(a, value) {
            if (Object.isDefined(value?.value) || value?.refused || value?.unknown) {
                return Promise.resolve();
            } 
            return Promise.reject();
        },
    }),
    estimated: () => ({
        validator(_, value) {
            if (value?.estimated && !value?.value) {
                return Promise.reject();
            } 
            return Promise.resolve();
        },
    }),
    email: {
        pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g,
        message: 'Invalid Email',
    },
    date: () => ({
        validator(_, value) {
            const date = value?.value;
            if (date && dayjs(date) < dayjs('1900-01-01')) {
                return Promise.reject(Error('The date cannot be before January 1, 1900.'));
            } 
            return Promise.resolve();
        },
    }),
};
