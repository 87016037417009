import IncidentsService from './incidents';
import LocationsService from './locations';
import NotesService from './notes';
import QuestionsService from './questions';
import CommonService from './common';
import DirectivesService from './directives';
import DistributionService from './distributions';
import FacilitiesService from './facilities';
import { AuthService } from './api';

const EnterpriseService = {
    locations: LocationsService,
    incidents: IncidentsService,
    notes: NotesService,
    questions: QuestionsService,
    directives: DirectivesService,
    facilities: FacilitiesService,
    distributions: DistributionService,
    common: CommonService,
    auth: AuthService
};

export { EnterpriseService }; 
