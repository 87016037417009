import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Input, Popover, Form } from 'library';
import { EnterpriseService } from 'services';
import { SearchOutlined } from '@ant-design/icons';
import IncidentContext from 'routes/Incident/context';
import { LookupDropdown } from '../Dropdown';
import config from './config';

const Field = ({ value, onChange, disabled, required, setRequired }) => {
    const { update } = useContext(IncidentContext);
    const form = Form.useFormInstance();
    const [open, setOpen] = useState(false);
    const { ediUnique: clientNumber } = useSelector(state => state.location);
    
    const service = filters => EnterpriseService.locations.externalLocations({
        ...filters,
        clientNumber,
    });

    const onOk = async (item) => {
        const { fields, thirdParty } = item;
        if (update) {
            return update({ fields, thirdParty: { location: thirdParty } });
        } 
        onChange(thirdParty);
        form.setFieldsValue({ fields });
        return Promise.resolve();
    };

    const onCancel = () => {
        setRequired(false);
        form.validateFields([['thirdParty', 'location']]);
        setOpen(false);
    };
    const initialValue = value?.name || value?.employer_location_name;
    return (
        <Popover
            content={(
                <LookupDropdown
                    onCancel={onCancel}
                    onOk={onOk}
                    title="Gallagher Bassett Location Search"
                    service={service}
                    defaultFilters={{
                        name: initialValue
                    }}
                    setOpen={setOpen}
                    {...config}
                />
            )}
            open={open}
            overlayClassName="lookup-popover w850"
            getPopupContainer={trigger => trigger.parentNode.parentNode}
        >
            <Input
                onClick={() => setOpen(true)}
                className="cursorPointer"
                readOnly
                suffix={<SearchOutlined onClick={() => setOpen(!open)} />}
                value={initialValue}
                disabled={disabled}
                placeholder={required ? 'Required' : null}
            />
        </Popover>
    );
};

Field.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    setRequired: PropTypes.func.isRequired
};

Field.defaultProps = {
    value: undefined,
    onChange: undefined,
};

export default Field;
