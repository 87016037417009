import './ButtonUnknown.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const ButtonUnknown = ({ onChange, value, disabled }) => (
    <Tooltip title="Unknown">
        <Button
            onClick={() => onChange({ unknown: !value?.unknown })}
            className={`btn-value btn-unk ${value?.unknown ? 'selected' : ''}`}
            icon={<QuestionCircleOutlined />}
            disabled={disabled}
            tabIndex={-1} 
        />
    </Tooltip>
);

ButtonUnknown.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired
};

ButtonUnknown.defaultProps = {
    value: undefined,
    onChange: undefined
};

export default ButtonUnknown;
