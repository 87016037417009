import './assets/styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, App as AntdApp } from 'antd';
import { store } from 'store';
import { EscapeAntd } from 'library';
import theme from 'assets/theme';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => <Routes />;

root.render((
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider {...theme}>
                <AntdApp>
                    <EscapeAntd />
                    <App />
                </AntdApp>
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
