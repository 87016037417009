import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Popover } from 'library';
import { EnterpriseService } from 'services';
import { SearchOutlined } from '@ant-design/icons';
import { setLocation } from 'store/location';
import { LookupDropdown } from '../Dropdown';
import config from './config';

const LookupLocationField = ({ value, onChange, disabled, defaultFilters }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const location = useSelector(state => state.location);

    const onOk = async (item) => {
        const newLocation = item.thirdParty;
        if (newLocation) {
            onChange(newLocation.id);
            await dispatch(setLocation(newLocation));
        }
        return Promise.resolve();
    };

    const service = args => EnterpriseService.locations.list({
        ...args,
        reportableLocation: 1,
        sortBy: 'title',
        sortDirection: 'asc',
        page: 1,
        perPage: 500,
    });

    return (
        <Popover
            content={(
                <LookupDropdown
                    onCancel={() => setOpen(false)}
                    onOk={onOk}
                    setOpen={setOpen}
                    service={service}
                    defaultFilters={defaultFilters}
                    {...config}
                />
            )}
            trigger="click"
            placement="bottomRight"
            open={!disabled && open}
            onOpenChange={setOpen}
            overlayClassName={`lookup-popover ${config.className}`}
            getPopupContainer={trigger => trigger.parentNode.parentNode}
        >
            <Input
                className="cursorPointer"
                readOnly
                suffix={<SearchOutlined onClick={() => setOpen(!open)} />}
                value={location?.title || value}
                disabled={disabled}
                placeholder="Required"
            />
        </Popover>
    );
};

LookupLocationField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultFilters: PropTypes.object.isRequired,
};

LookupLocationField.defaultProps = {
    value: undefined,
    onChange: undefined,
    disabled: false,
};

export default LookupLocationField;
