import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

const ButtonWithConfirm = ({ buttonProps, children, ...rest }) => (
    <Popconfirm
        {...rest}
    >
        <Button {...buttonProps}>{children}</Button>
    </Popconfirm>
);

ButtonWithConfirm.propTypes = {
    children: PropTypes.any,
    buttonProps: PropTypes.object
};

ButtonWithConfirm.defaultProps = {
    buttonProps: {},
    children: undefined
};

export { ButtonWithConfirm };
