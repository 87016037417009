import React, { useEffect, useContext } from 'react';
import { BaseTable } from 'library';
import { useSelector } from 'react-redux';
import { EnterpriseService } from 'services';
import { useService } from 'hooks';
import getColumns from './config';
import FacilityContext from '../../context';

const ExternalFacilitiesTab = () => {
    const { onFacilityChange, facility, preferredIds, address } = useContext(FacilityContext);
    const incident = useSelector(state => state.incident);
    const service = EnterpriseService.facilities.list;
    const [loadFacilities, { data: { data }, loading }] = useService(service, { data: [] });

    useEffect(() => {
        if (incident.locationId && address) {
            loadFacilities({
                filters: {
                    source: 'talispoint',
                    locationId: incident.locationId,
                    distanceFromAddress: address,
                }
            });
        }
    }, [loadFacilities, incident.id, incident.locationId, address]);

    const columns = getColumns({ preferredIds });

    const dataSource = data.sort((a, b) => {
        if (a.distance === undefined && b.distance === undefined) {
            return 0;
        }
        if (a.distance === undefined) {
            return 1;
        }
        if (b.distance === undefined) {
            return -1;
        }
        return a.distance - b.distance;
    });

    return (
        <BaseTable
            rowKey="id"
            className="external-facilities-table"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                type: 'radio',
                onChange: (_, [item]) => onFacilityChange(item),
                selectedRowKeys: facility?.id ? [facility.id] : [],
            }}
            onRow={item => ({
                onClick: () => onFacilityChange(item)
            })}
            size="small"
            loading={loading}
            scroll={{ x: 'max-content' }}
        />
    );
};

export default ExternalFacilitiesTab; 
