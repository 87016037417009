import './SendToTriage.scss';
import dayjs from 'dayjs';
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Radio, Form, InputDate, InputTime, Space, InputPhone } from 'library';
import { QUEUE_ID_RN, ROUTING_STATUS, INCIDENT_STATE, StateAttributes } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import { transferToRN, consultRN } from 'store/call';
import { GenesysAuthService, GenesysQueueService } from 'services';

import { Rules, catchError } from 'utils';
import IncidentContext from 'routes/Incident/context';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'HH:mm';

const SendToTriageModal = ({ open, setOpen }) => {
    const form = Form.useFormInstance();
    const { update, onTriageSuccess } = useContext(IncidentContext);
    const dispatch = useDispatch();
    const incident = useSelector(state => state.incident);
    const location = useSelector(state => state.location);
    const [lines, setLines] = useState({ all: 'N/A', idle: 'N/A' });
    const [loading, setLoading] = useState(false);
    const [dateChanged, setDateChanged] = useState(false);
    const [innerForm] = Form.useForm();
    const [action, setAction] = useState('TRANSFER');
    const [initialValues, setInitialValues] = useState();
    const isSignedIn = GenesysAuthService.isSignedIn();

    useEffect(() => {
        if (isSignedIn && open) {
            GenesysQueueService.getMembers(QUEUE_ID_RN)
                .then((res) => {
                    const idle = res
                        .filter(item => item.routingStatus === ROUTING_STATUS.IDLE);
                    setLines({
                        all: res.length,
                        idle: idle.length
                    });
                })
                .catch(catchError);
        }
    }, [form, isSignedIn, open]);

    // Fill Initial callback
    useEffect(() => {
        if (open) {
            const callbackPhone = form.getFieldValue(['fields', 'caller_phone'])?.value;
            setInitialValues({
                callbackPhone,
                callbackDate: dayjs().format(DATE_FORMAT),
                callbackTime: dayjs().format(TIME_FORMAT),
            });
        }
    }, [open, form, innerForm]);

    useEffect(() => {
        if (initialValues) {
            innerForm.resetFields();
        }
    }, [initialValues, innerForm]);

    const getIncidentState = () => {
        const injuryState = form.getFieldValue(['fields', 'injury_state'])?.value;
        const locationState = location.state;
        const startsWith = `${(injuryState || locationState || '').toUpperCase()} - `;
        return StateAttributes.find(name => name.startsWith(startsWith));
    };

    const actionCreateCallback = ({ callbackDate, callbackTime, callbackPhone }) => {
        const formValues = form.getFieldsValue(true);
        const data = {
            ...formValues,
            callbackPhone,
            incidentState: INCIDENT_STATE.TRIAGE
        };
        if (dateChanged) {
            const callbackDateTime = dayjs(`${callbackDate} ${callbackTime}`)
                .format(DATE_TIME_FORMAT);
            data.callbackDateTime = callbackDateTime;
        }
        setLoading(true);
        update(data)
            .then(onTriageSuccess)
            .finally(() => setLoading(false));
    };
  
    const actionTransferToNurse = () => {
        setLoading(true);
        const formValues = form.getFieldsValue(true);
        update({
            ...formValues,
            incidentState: INCIDENT_STATE.TRIAGE
        })
            .then(() => {
                const SelectedState = getIncidentState();
                dispatch(transferToRN(SelectedState));
            })
            .then(onTriageSuccess)
            .finally(() => setLoading(false));
    };

    const actionConsultWithNurse = () => {
        setLoading(true);
        const formValues = form.getFieldsValue(true);
        update({
            ...formValues,
            incidentState: INCIDENT_STATE.TRIAGE
        })
            .then(() => {
                const SelectedState = getIncidentState();
                dispatch(consultRN(SelectedState));
            })
            .then(onTriageSuccess)
            .finally(() => setLoading(false));
    };

    const Actions = {
        CALLBACK: actionCreateCallback,
        TRANSFER: actionTransferToNurse,
        CONSULT: actionConsultWithNurse
    };

    const isCallback = action === 'CALLBACK';

    return (
        <Modal
            className="info modal-choose-to-continue modal-triage"
            title="Send Call & Report to Nurse"
            open={open}
            confirmLoading={loading}
            onOk={innerForm.submit}
            onCancel={() => setOpen(false)}
            okText="Apply"
            okButtonProps={{ children: 'Send', className: 'info', loading: loading || incident.$updating }}
            cancelButtonProps={{ type: 'text' }}
            closable={false}
            centered
        >
            <div className="content">
                <Form
                    form={innerForm}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={Actions[action]}
                    requiredMark={false}
                >
                    <Radio.Group
                        onChange={e => setAction(e.target.value)}
                        value={action}
                    >
                        <h2 className="mb">
                            Registered Nurse
                            {' '}
                            <span className={`available-${lines.idle}`}>
                                (Lines Available:{' '}
                                <b>{lines.idle}</b>
                                {' '}/{' '}{lines.all})
                            </span>
                        </h2>

                        <Radio
                            value="TRANSFER"
                            className={`internal ${action === 'TRANSFER' ? 'active' : ''}`}
                        >
                            <div>
                                <span>Transfer to a Registered Nurse</span>
                            </div>
                        </Radio>
                        <Radio
                            value="CONSULT"
                            className={`internal ${action === 'CONSULT' ? 'active' : ''}`}
                        >
                            <div>
                                <span>Consult a Registered Nurse</span>
                            </div>
                        </Radio>

                        <h2>Request Callback</h2>
                        <Radio
                            value="CALLBACK"
                            className={`callback-form ${isCallback ? 'active' : ''}`}
                        >
                            <Space>
                                <Form.Item
                                    label="Phone"
                                    name="callbackPhone"
                                    rules={isCallback ? [Rules.required] : []}
                                >
                                    <InputPhone />
                                </Form.Item>
                                <Form.Item
                                    label="Date"
                                    name="callbackDate"
                                    rules={isCallback ? [Rules.required] : []}
                                >
                                    <InputDate onChange={() => setDateChanged(true)} />
                                </Form.Item>

                                <Form.Item
                                    label="Time"
                                    name="callbackTime"
                                    rules={isCallback ? [Rules.required] : []}
                                >
                                    <InputTime onChange={() => setDateChanged(true)} />
                                </Form.Item>
                            </Space>
                        </Radio>
                    </Radio.Group>
                </Form>
            </div>
        </Modal>
    );
};

SendToTriageModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default SendToTriageModal;
