import './ButtonCallActions.scss';
import React, { useState, useEffect } from 'react';
import Icon, { UserAddOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dropdown, Button, Spin } from 'library';
import { EnterpriseService } from 'services';
import { useService } from 'hooks';
import { IconInsurance, IconRN } from 'assets';
import TransferToInsuranceModal from './TransferToInsuranceModal';
import InviteInterpreterModal from './InviteInterpreterModal';
import TransferToRnModal from './TransferToRnModal';

const getContactsService = EnterpriseService.locations.insuranceContacts;

const ButtonCallActions = ({ setInterpreter }) => {
    const [loadContacts, { data: insuranceContacts, loading }] = useService(getContactsService, []);
    const { id: locationId, $loading: locationLoading } = useSelector(state => state.location);
    const [openInsurance, setOpenInsurance] = useState(false);
    const [openInterpreter, setOpenInterpreter] = useState(false);
    const [openRn, setOpenRn] = useState(false);

    useEffect(() => {
        if (locationId) {
            loadContacts(locationId);
        }
    }, [locationId, loadContacts]);

    const items = [
        {
            key: 'interpreter',
            label: 'Interpreter Options',
            icon: locationLoading || loading ? <Spin size="small" /> : <UserAddOutlined />,
            onClick: () => setOpenInterpreter(true)
        },
        {
            key: 'insurance',
            label: 'Transfer to Insurance',
            icon: locationLoading || loading ? <Spin size="small" /> : <Icon component={IconInsurance} />,
            disabled: !insuranceContacts.length,
            onClick: () => setOpenInsurance(true),
        },
        {
            key: 'RN',
            label: 'Transfer Call to RN Queue',
            icon: <Icon component={IconRN} />,
            danger: true,
            onClick: () => setOpenRn(true)
        },
    ];

    return (
        <>
            {openRn && (
                <TransferToRnModal
                    setOpen={setOpenRn}
                    contacts={insuranceContacts}
                />
            )}
            {openInsurance && (
                <TransferToInsuranceModal
                    setOpen={setOpenInsurance}
                    contacts={insuranceContacts}
                />
            )}
            {openInterpreter && (
                <InviteInterpreterModal
                    setOpen={setOpenInterpreter}
                    setInterpreter={setInterpreter}
                />
            )}
            <Dropdown
                arrow
                placement="top"
                menu={{ items }}
                trigger={['click']}
                overlayClassName="call-actions-dropdown"
            >
                <Button type="default left">
                    Call Actions ...
                </Button>
            </Dropdown>
        </>
    );
};

ButtonCallActions.propTypes = {
    setInterpreter: PropTypes.func,
};

ButtonCallActions.defaultProps = {
    setInterpreter: () => { }
};
export { ButtonCallActions };
