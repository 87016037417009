import axios from 'axios';
import { ENTERPRISE_URL } from 'config';
import { toQueryParams } from 'utils';
import AuthServiceClass from './auth';

const api = axios.create({
    baseURL: ENTERPRISE_URL,
    timeout: 10 * 60 * 1000,
    headers: {
        Accept: 'application/json',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store',
    },
    responseType: 'json'
});

const AuthService = new AuthServiceClass(api);
export { AuthService }; 

const isLoginRequest = config => config?.url.includes('auth/login');

api.get = (url, params) => api(toQueryParams(params, url));

api.interceptors.request.use(async (config) => {
    if (isLoginRequest(config)) {
        return config;
    }
    const token = await AuthService.token();
    
    return {
        ...config,
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
        },
        retry: config.retry !== false
    };
});

api.interceptors.response.use(
    response => response,
    async (error = {}) => {
        const response = error?.response || {};
        const { data, status: $code } = response;
        const { config } = error;
        
        if (isLoginRequest(config)) {
            if ($code === 401) {
                return Promise.resolve();
            }
        }

        if (config?.retry && $code === 401) {
            const token = await AuthService.token(true);
            const newConfig = {
                ...config,
                retry: false,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                }
            };
            return api(newConfig);
        }
        if (data?.message) {
            return Promise.reject(Error(data.message, { cause: data.errors }));
        }
        return Promise.reject(error);
    }
);

export default api;
