import { toQueryParams } from 'utils';
import api from '../api';

class EnterpriseCommonService {
    static search(uri, { id, query, page = 1, perPage = 100 }) {
        const url = toQueryParams({ id, page, perPage, query }, uri);
        return api.get(url)
            .then(({ data }) => data);
    }
}

export default EnterpriseCommonService;
