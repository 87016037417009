/* eslint-disable no-console */
import { SubHeader } from 'layout';
import { Button } from 'library';
import { EnterpriseService, GenesysAuthService } from 'services';
import api from '../../services/enterprise/api';

const ApiPlayground = () => {
    const onClick = () => {
        const data = {
            pureCloudConversationId: 'e0300302-b7c5-4291-be75-d74405472aa2',
            locationId: 337,
            episodeTypeId: 1,
            selfReportValue: 1,
            callerPhone: '+18014443844',
            callerFirstName: 'callerFirstName',
            callerLastName: 'callerLastName',
            claimantLanguageCode: '1',
            identifiedLocation: 'ACME',
            fields: {
                caller_phone: { value: '+18014443844' },
                caller_first_name: { value: 'Test' },
                caller_last_name: { value: 'TEst' },
                claimant_first_name: { value: 'Test' },
                claimant_last_name: { value: 'TEst' },
                claimant_work_phone: { value: '+18014443844' },
                claimant_language_code: { value: '1' },
                injury_state: { value: 'WY' }
            }
        };

        EnterpriseService.incidents.create(data)
            .then((incident) => {
                console.info(incident);
            });
    };

    const listIncidents = () => {
        api.post('/api/v2/incidents/searches', {
            /* claimantBirthDate: {
                value: '2024-04-10'
            },
            claimantSsn: {
                value: '74',
                pattern: 'LIKE',
                or: true
            }, locationId: [28463],
            assignedPhoneNumber: {
                value: '+19452533887',
                pattern: 'PHONE',
                or: true
            }, */
            // claimantFirstLastName: 'blaga yordanova',
            locationMatch: {
                assignedPhoneNumber: '+19452533887',
                id: 28463
            },
            claimantMatch: {
                claimantFirstLastName: 'James Acosta',
                claimantSsn: '74',
                claimantBirthDate: '2024-04-10'
            }
            
        });
    };

    return (
        <>
            <SubHeader
                title="API Playground"
            />
            <div className="container">
                <div style={{ margin: 40, display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: 10, width: 600 }}>
                    <Button onClick={onClick}>
                        Create Incident
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => EnterpriseService.auth.deactivateToken()}
                    >
                        Deactivate ES Token

                    </Button>
                    <Button
                        type="primary"
                        onClick={() => GenesysAuthService.deactivateToken()}
                    >
                        Deactivate GS Token
                    </Button>
                    <Button onClick={listIncidents}>
                        Find Duplictes
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ApiPlayground;
