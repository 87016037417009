import './IncidentType.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select } from 'library';
import { getIncidentType } from 'config';
import { usePrevious } from 'hooks';

const SelectIncidentType = ({ isNew, value, onChange, ...rest }) => {
    const [thisValue, setThisValue] = useState();
    const { id: locationId, episodeTypeId } = useSelector(state => state.location);
    const { incidentTypeId: callIncidentTypeId } = useSelector(state => state.call);
    const { abbr } = getIncidentType(thisValue);
    const prevLocation = usePrevious({ id: locationId });

    const incidentTypeOptions = episodeTypeId?.map(item => ({
        value: item.id,
        label: item.title,
    }));
 
    // https://objectsystems.atlassian.net/browse/LGB-252
    const prevLocationId = prevLocation?.id;
    const isLocationChanged = (isNew || prevLocationId)
        && (prevLocationId !== locationId) && !!locationId;

    useEffect(() => {
        if (isLocationChanged) {
            if (episodeTypeId.length === 1) {
                onChange(episodeTypeId[0].id);
            } else if (episodeTypeId.find(item => item.id === value)) {
                onChange(value);
            } else if (episodeTypeId.find(item => item.id === callIncidentTypeId)) {
                onChange(callIncidentTypeId);
            } else {
                onChange();
            }
        }
    }, [isLocationChanged, episodeTypeId, callIncidentTypeId, onChange, value]);

    useEffect(() => {
        if (isNew && callIncidentTypeId) {
            onChange(callIncidentTypeId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callIncidentTypeId, isNew]);

    useEffect(() => {
        if (value !== thisValue) {
            setThisValue(value);
        }
    }, [value, thisValue]);

    return (
        <Select
            value={thisValue}
            onChange={onChange}
            placeholder="Select an Incident Type"
            options={incidentTypeOptions}
            className={`incident-type-select ${abbr}`}
            {...rest}
        />
    );
};

SelectIncidentType.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    isNew: PropTypes.bool
};

SelectIncidentType.defaultProps = {
    value: undefined,
    onChange: undefined,
    isNew: false
};

export { SelectIncidentType };
