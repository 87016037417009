/* eslint-disable max-len */
import { QUEUE_ID_INTERPRETERS, QUEUE_ID_RN } from 'config';
import dayjs from 'dayjs';
import { notification } from 'library';
import { catchError } from 'utils';
import { conversationsApi } from '../utils';
import { transformConversation } from './transform';

const FlowTransferToRN = process.env.REACT_APP_FLOW_ID_TRANSFER_TO_RN;

class ConversationService {
    static get(conversationId) {
        return conversationsApi
            .getConversation(conversationId)
            .then(transformConversation);
    }

    static #disconnect(conversationId) {
        return conversationsApi
            .patchConversationsCall(conversationId, { state: 'DISCONNECTED' });
    }

    static #connect(conversationId, participantId) {
        return conversationsApi
            .patchConversationsCallParticipant(
                conversationId,
                participantId,
                { state: 'CONNECTED' }
            );
    }

    static getActiveConversation(errorDescription = null) {
        return conversationsApi.getConversations({
            communicationType: 'Call'
        })
            .then(data => data.entities)
            // .then(data => data.find(item => !item.agent?.endTime))
            .then(data => (data.length ? data[data.length - 1] : null))
            .then((conversation) => {
                if (!conversation?.id && errorDescription) {
                    return catchError({
                        message: `Cannot ${errorDescription}`,
                        description: 'Active Conversation Not Found',
                    });
                }
                return transformConversation(conversation);
            });
    }

    // Replace this participant with the specified user and/or address
    static #transfer(call, to) {
        try {
            const conversationId = call.id;
            const participantId = call.agent.id;
            return conversationsApi
                .postConversationsCallParticipantReplace(
                    conversationId,
                    participantId,
                    to
                );
        } catch (e) {
            return Promise.reject(new Error(e));
        }
    }

    // Add participants to a conversation
    static #consultTransfer(call, data) {
        try {
            const conversationId = call.id;
            const participantId = call.customer.id;

            return conversationsApi
                .postConversationsCallParticipantConsult(
                    conversationId,
                    participantId,
                    data
                );
        } catch (e) {
            return Promise.reject(new Error(e));
        }
    }

    /* 
    // GENESYS
    https://api.usw2.pure.cloud/api/v2/conversations/calls/db36a3c9-c8d4-4c35-b4b4-09527bd42b8a/participants/c7401999-d7ea-48ea-a97b-7b32b7a32b1e/consult
    {
    "speakTo": "DESTINATION",
    "destination": {
        "queueId": "97f92e98-c998-405b-b913-619ce373fa89"
    }
    } 
    
    // LAX
    https://api.usw2.pure.cloud/api/v2/conversations/calls/8656770e-5634-46ad-bd24-ab49ef2a0586/participants/2b14e1ac-73d2-43bd-96a4-25b3467b59e7/consult
    {
    "speakTo": "DESTINATION",
    "destination": {
        "queueId": "97f92e98-c998-405b-b913-619ce373fa89"
    }
}
*/

    static #consultTransferToQueue(call) {
        try {
            const conversationId = call.id;
            const participantId = call.customer.id;
            return conversationsApi
                .postConversationsCallParticipantConsult( // postConversationsCallParticipantConsultQueue
                    conversationId,
                    participantId,
                    { 
                        speakTo: 'DESTINATION', // DESTINATION, CONFERENCE, BOTH, OBJECT, CONFERENCE
                        destination: {
                            queueId: QUEUE_ID_RN,
                        },
                    }
                );
        } catch (e) {
            return Promise.reject(new Error(e));
        }
    }

    /* PATCH https://api.usw2.pure.cloud/api/v2/conversations/[id]/participants/[customer]/attributes */
    static #setAttributesToCustomer(call, attributes) {
        try {
            const conversationId = call.id;
            const participantId = call.customer?.id;
            return conversationsApi
                .patchConversationParticipantAttributes(
                    conversationId,
                    participantId,
                    {
                        attributes
                    }
                );
        } catch (e) {
            return Promise.reject(new Error(e));
        }
    }

    static async consultRN(SelectedState) {
        return ConversationService.getActiveConversation('Consult Transfer to RN')
            .then(async (call) => {
                await ConversationService.#setAttributesToCustomer(call, { SelectedState });
                return call;
            })
            .then(ConversationService.#consultTransferToQueue);
    }

    /*
    https://apps.usw2.pure.cloud/architect/#/flowSearch/inqueueCallFlow/1208565a-e894-4fc6-8f7b-6bbc54a17474
    */
    static async transferToRN(SelectedState) { 
        /* const _transfer = () => ConversationService.#consultTransfer(call, {
            speakTo: 'DESTINATION',
            destination: {
                queueId: QUEUE_ID_RN
            }
        }); 
        const _transfer = () => ConversationService.#transfer(call, { queueId: QUEUE_ID_RN }) */
        const _transfer = (call) => {
            const conversationId = call.id;
            const participantId = call.customer.id;
            const agentId = call.agent.id;
            // const incidentNumber = call.customer.attributes?.IncidentNumber || 'IncidentNumber2';

            return conversationsApi
                .postConversationParticipantSecureivrsessions(
                    conversationId,
                    participantId,
                    {
                        body: {
                            flowId: FlowTransferToRN,
                            // userData: `${SelectedState}|false|${incidentNumber}`,
                            disconnect: true,
                            sourceParticipantId: agentId
                        }
                    }
                );
        };

        return ConversationService.getActiveConversation('Transfer Conversation to RN Queue')
            .then(async (call) => {
                await ConversationService.#setAttributesToCustomer(call, { SelectedState });
                return call;
            })
            .then(_transfer)
            .then(() => {
                notification.success({ message: 'Transfer to RN Queue was successful' });
            });
    }

    static transferToPhone(phone) {
        const address = (phone.indexOf('+') !== 0 ? '+1' : '') + phone;
        return ConversationService.getActiveConversation('Transfer Conversation To Phone')
            .then(call => ConversationService.#transfer(call, { address }))
            .then(() => notification.success({ message: 'Transfer was successful' }));
    }

    static inviteExternal(phone) {
        const address = (phone.indexOf('+') !== 0 ? '+1' : '') + phone;
        return ConversationService.getActiveConversation('Invite External')
            .then(call => ConversationService.#consultTransfer(call, { destination: { address } }));
    }

    static inviteInternal() {
        return ConversationService.getActiveConversation('Invite Internal')
            .then(call => ConversationService.#consultTransfer(call, { 
                speakTo: 'DESTINATION',
                destination: { queueId: QUEUE_ID_INTERPRETERS } 
        }));
    }

    static transferToInternalInterpreter() {
        return ConversationService.getActiveConversation('Transfer to Internal Interpreter')
            .then(call => ConversationService.#transfer(call, { queueId: QUEUE_ID_INTERPRETERS }));
    }

    static async createRNCallback({ callbackPhone, callbackUserName, callbackDateTime, data }) {
        const callbackScheduledTime = dayjs.utc(callbackDateTime).format();
        const postData = {
            queueId: QUEUE_ID_RN,
            routingData: { queueId: QUEUE_ID_RN, skillIds: [] },
            callbackUserName,
            callbackNumbers: [callbackPhone],
            callbackScheduledTime,
            data
        };
        return conversationsApi.postConversationsCallbacks(postData);
    }
}

export default ConversationService; 
