import { useCallback, useMemo, useRef, useState } from 'react';
import { notification, Select } from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'utils';

import { Spin } from 'library';

const MIN_QUERY_LENGTH = 3;

function DebounceSelect({ fetchOptions, onChange, popupClassName, ...rest }) {
    const [query, setQuery] = useState();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [total, setTotal] = useState(undefined);
    const [page, setPage] = useState(1);
    const fetchRef = useRef(0);

    const fetchData = useCallback(params =>
        fetchOptions(params)
            .then((data) => {
                if (data) {
                    setOptions(data.data);
                    setTotal(data.total);
                    setPage(data.page);
                }
            })
            .catch(e => notification.error({ message: e.message }))
            .finally(() => setLoading(false)),
        [fetchOptions]);

    const debounceSearch = useMemo(() => {
        const loadOptions = (val) => {
            setQuery(val);
            if (val.length < MIN_QUERY_LENGTH) {
                return;
            }
            setLoading(true);
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            fetchData({ query: val, page: 1 }).then((data) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return undefined;
                }
                return data;
            });
        };
        return debounce(loadOptions, 800);
    }, [fetchData]);
    
    const handleFetchMore = (newPage) => {
        setLoading(true);
        fetchData({ query, page: newPage });
    };
    
    const handleScroll = (event) => {
        event.stopPropagation();
        const finished = total <= options.length;
        if (finished || loading) {
            return;
        }
        const delta = event.wheelDelta ? event.wheelDelta : -1 * event.deltaY;
        const el = document.querySelector('.debaunce-select-dropdown .rc-virtual-list-holder');
        const { scrollTop, scrollHeight, offsetHeight } = el;
        const contentHeight = scrollHeight - offsetHeight;
        const isCloseToEnd = contentHeight - scrollTop <= 250;
        if (delta < 0 && isCloseToEnd) {
            handleFetchMore(page + 1);
        }
    };

    const handleChange = (val) => {
        const item = options.find(opt => opt.key === val.key);
        if (item) {
            onChange(item);
        } 
    };

    const NotFoundContent = !loading && query?.length < MIN_QUERY_LENGTH
        ? `Please enter ${MIN_QUERY_LENGTH} or more characters` : null;

    return (
        <Select
            labelInValue
            showSearch
            filterOption={false}
            onSearch={debounceSearch}
            notFoundContent={NotFoundContent}
            popupMatchSelectWidth={false}
            loading={loading}
            options={options}
            onChange={handleChange}
            listHeight={350}
            popupClassName={`debaunce-select-dropdown ${popupClassName} `}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={menu => (
                <>
                    <div onWheel={handleScroll}>{menu}</div>
                    <div className="debounce-select-footer">
                        {loading && <Spin size="small" />}
                        {Object.isDefined(total) && <>Total: <b>{total}</b></>}
                    </div>
                </>
            )}
            {...rest}
        />
    );
}

DebounceSelect.propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    popupClassName: PropTypes.string
};

DebounceSelect.defaultProps = {
    onChange: undefined,
    popupClassName: ''
};

export default DebounceSelect;
