import { useRef, useEffect } from 'react';

const createDeepCompareEffect = hook => (effect, deps) => {
    const ref = useRef();
    const signalRef = useRef(0);

    if (deps === undefined || !Object.areEquals(deps, ref.current)) {
        ref.current = deps;
        signalRef.current += 1;
    }

    hook(effect, [signalRef.current]);
};

const useDeepCompareEffect = createDeepCompareEffect(useEffect);

export { useDeepCompareEffect };
