import { toQueryParams } from 'utils';
import { INCIDENT_TYPE_WC } from 'config';
import api from '../api';
import transformList from './transform.list';

class EnterpriseQuestionsService {
    static async list({ id, episodeTypeId = INCIDENT_TYPE_WC }) {
        const categories = await EnterpriseQuestionsService.categories({ episodeTypeId });
        
        const params = toQueryParams({ episodeTypeId });
        return api.get(`/api/v2/locations/${id}/questions?${params}`)
            .then(data => transformList(id, data, categories));
    }

    static async categories(filters = {}) {
        const url = toQueryParams(filters, '/api/v2/question-categories');
        const { data } = await api.get(url);
        return data;
    }
}

export default EnterpriseQuestionsService;
