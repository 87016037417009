/* eslint-disable jsx-a11y/click-events-have-key-events */
import './Duplicate.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { EnterpriseService } from 'services';
import { useSelector } from 'react-redux';
import { PauseCircleFilled } from '@ant-design/icons';
import { IncidentProps } from './DuplicateConfig';
import { IncidentCard } from '../IncidentCard';

const IncidentDuplicate = ({ incidentTypeId, item, setOpen, incidents }) => {
    const incident = useSelector(state => state.incident);
    const navigate = useNavigate();
    const [updating, setUpdating] = useState(false);

    const onAcceptClick = () => {
        setUpdating(true);
        if (!incident.id) {
            setOpen(false);
            navigate(`/incidents/${item.id}/preview`);
            return;
        }
        const ids = incidents.map(it => it.id).join(',');
        EnterpriseService.incidents.update(incident.id, {
            matches: {
                memo: `Accepted: ${item.id}, Incidents: [${ids}]`,
                value: 'Accept'
            }
        })
            .then(() => {
                setOpen(false);
                navigate(`/incidents/${item.id}/preview`);
            })
            .finally(() => setUpdating(false));
    };

    return (
        <IncidentCard
            incident={item}
            onClick={onAcceptClick}
            loading={updating}
            className="duplicate"
        >
            {IncidentProps
                .filter(it => !it.incidentTypes || it.incidentTypes.includes(incidentTypeId))
                .map(({ label, key }) => (
                    <React.Fragment key={key}>
                        <div className={item[`$${key}`]}>
                            <PauseCircleFilled /> {label}
                        </div>
                        <span>{item[key]}</span>
                    </React.Fragment>
                ))}
        </IncidentCard>
    );
};

IncidentDuplicate.propTypes = {
    item: PropTypes.object.isRequired,
    incidents: PropTypes.array.isRequired,
    setOpen: PropTypes.func.isRequired,
    incidentTypeId: PropTypes.number
};

IncidentDuplicate.defaultProps = {
    incidentTypeId: undefined
};
export default IncidentDuplicate;
