import './ButtonRefused.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { StopOutlined } from '@ant-design/icons';

const ButtonRefused = ({ value, onChange, disabled }) => (
    <Tooltip title="Refused">
        <Button
            onClick={() => onChange({ refused: !value?.refused })}
            className={`btn-value  btn-ref ${value?.refused ? 'selected' : ''}`}
            icon={<StopOutlined />}
            disabled={disabled}
            tabIndex={-1} 
        />
    </Tooltip>
);

ButtonRefused.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired
};

ButtonRefused.defaultProps = {
    value: undefined,
    onChange: undefined
};

export default ButtonRefused;
