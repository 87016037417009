import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { EnterpriseService } from 'services';
import { useService } from 'hooks';
import { BaseTable } from 'library';
import columns from './config';
import FacilityContext from '../../context';
import { ButtonExpand } from '../../components';

const DesignatedFacilitiesTab = () => {
    const { facility, onFacilityChange, setPreferredIds, address } = useContext(FacilityContext);
    const incident = useSelector(state => state.incident);
    const { useExternalFacilities } = useSelector(state => state.location);
    const service = EnterpriseService.facilities.listByLocation;
    const [loadInternal, { data: internal, loading }] = useService(service, []);
    const [loadExternal, { data: external, loadingEx }] = useService(service, []);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        if (incident.locationId) {
            loadInternal(incident.locationId, {
                distanceFromAddress: address
            });
        }
    }, [incident.locationId, loadInternal, address]);

    useEffect(() => {
        if (incident.locationId && address && useExternalFacilities) {
            loadExternal(incident.locationId, {
                source: 'talispoint',
                incidentId: incident.id,
                distanceFromAddress: address
            });
        }
    }, [incident.locationId, loadExternal, useExternalFacilities, incident.id, address]);

    useEffect(() => {
        const ide = external.map(item => item.id);
        const idi = internal.map(item => item.id);
        setPreferredIds([...ide, ...idi]);
    }, [external, internal, setPreferredIds]);

    const dataSource = [
        ...external,
        ...internal
    ].sort((a, b) => b.priority - a.priority || a.distance - b.distance);

    const toggleExpanded = (id) => {
        if (expanded.includes(id)) {
            setExpanded(expanded.filter(item => item !== id));
        } else setExpanded([...expanded, id]);
    };

    const onRow = item => ({
        onClick: item.children?.length
            ? () => toggleExpanded(item.id)
            : () => onFacilityChange(item)
    });

    const expandalbleKeys = dataSource.filter(it => it.children?.length > 0).map(it => it.id);
    
    return (
        <BaseTable
            rowKey="id"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            size="small"
            loading={loading || loadingEx}
            scroll={{ x: 'max-content', y: 500 }}
            rowSelection={{
                columnWidth: 50,
                columnTitle: (
                    <ButtonExpand
                        setExpanded={setExpanded}
                        expanded={expanded}
                        expandalbleKeys={expandalbleKeys}
                    />
                ),
                type: 'radio',
                onChange: (_, [item]) => onFacilityChange(item),
                selectedRowKeys: facility?.id ? [facility.id] : [],
                getCheckboxProps: item => ({
                    disabled: item.children?.length > 0,
                    style: item.children?.length > 0 ? { display: 'none' } : null
                }),

            }}
            expandable={{
                expandedRowKeys: expanded,
                onExpandedRowsChange: setExpanded
            }}
            onRow={onRow}
            rowClassName={item => (item.facilityProviderContactId ? 'is-contact' : '')}
        />
    );
};

export default DesignatedFacilitiesTab;
