import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import areEquals from 'react-fast-compare';

export * from './uri.utils';
export * from './format.utils';
export * from './form.utils';
export * from './data.utils';
export * from './browser.utils';
export * from './array.utils';
export * from './error.utils';
export * from './debounce.utils';
export * from './promise.utils';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

Object.isObject = x => Object.prototype.toString.call(x) === '[object Object]';

Object.isFunction = x => Object.prototype.toString.call(x) === '[object Function]';

Object.isEmpty = x => Object.keys(x || {}).length === 0;

Object.isDefined = x => (typeof x !== 'undefined');

Object.isUndefined = x => (typeof x === 'undefined');

Object.isBool = x => (typeof x === 'boolean');

Object.isString = x => Object.prototype.toString.call(x) === '[object String]';

Object.isNumber = x => Number.isInteger(x);

Object.removeEmptyProps = x => (!x ? {} : Object.fromEntries(
    Object.entries(x)
        .filter(([, v]) => v != null && v?.toString().trim() !== '')
        .map(([k, v]) => [k, v === Object(v) ? Object.removeEmptyProps(v) : v])
));

Object.hasAllProps = (x) => {
    const len = Object.keys(x).length;
    return Object.keys(Object.removeEmptyProps(x)).length === len;
};

Object.areShallowEquals = (x, y) =>
    Object.keys(x).length === Object.keys(y).length
    && Object.keys(x).every(key => x[key] === y[key]);

Object.areEquals = (x, y) => areEquals(x, y);

Object.findProp = function findProp(x, key) {
    let value;
    Object.keys(x).some((k) => {
        const val = x[k];
        if (k === key) {
            value = x[k];
            return true;
        }
        if (val && Object.isObject(val)) {
            value = Object.findProp(val, key);
            return value !== undefined;
        }
        return !!value;
    });
    return value;
};
