import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'library';
import { EnterpriseService } from 'services';
import { useService } from 'hooks';
import { FacilityTypeIcon } from 'config';
import { FacilityServices } from './Services';
import { FacilityOpenHours } from './OpenHours';

const FacilityCard = ({ id }) => {
    const [get, { data, loading }] = useService(EnterpriseService.facilities.get, {});

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id, get]);

    if (!id) {
        return null;
    }

    return (
        <Spin spinning={loading}>
            <div className="data-grid">
                <div>Name: </div><span>{data.name}</span>
                <div>Code: </div><span>{data.code}</span>
                <div>ER</div><span>{FacilityTypeIcon[data.type]}</span>
                <div>Open Hours</div><span><FacilityOpenHours item={data} /></span>
                <div>Phone</div><span>{data.phone}</span>
                <div>Fax</div><span>{data.fax}</span>
                <div>Address</div><span>{data.address}</span>
                <div>City, State, Zip</div><span> {data.city} {' '} {data.state} {' '} {data.zip}</span>
                <div>Notes</div><span>{data.referralNotes}</span>
                <div>Services</div><span><FacilityServices item={data} /></span>
            </div>
        </Spin>
    );
};

FacilityCard.propTypes = {
    id: PropTypes.number
};

FacilityCard.defaultProps = {
    id: undefined
};

export { FacilityCard };
