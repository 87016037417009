import './IncidentNoTriageSummary.scss';
import IncidentSummary from 'components/IncidentSummary';
import { EnterpriseService } from 'services';
import { Grid, SubHeader } from 'layout';
import { IconNoTriage } from 'assets';
import FormSummary from './Form';
import Footer from './Footer';

const IncidentNoTriageSummary = () => (
    <>
        <SubHeader
            prefix={<img src={IconNoTriage} alt="Incident Summary" />} 
            title="Incident Summary"
        />
        <Grid className="grid-no-triage-summary">
            <FormSummary>
                <Footer />
            </FormSummary>
            <IncidentSummary
                noteType={EnterpriseService.notes.ALL_TYPE}
            />
        </Grid>
    </>
);

export default IncidentNoTriageSummary;
