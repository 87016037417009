import { getIncidentType, FLOW_TYPE } from 'config';

const EmptyEnspiriDates = ['0000-00-00', '0000-00-00 00:00:00', '', null];

const dateValue = val => (EmptyEnspiriDates.includes(val) ? undefined : val);

const transformGet = (data) => {
    if (!data) {
        return undefined;
    }
    const { fields, incidentType, relatedIncident } = data;
    const $isReportOnly = incidentType?.flowType !== FLOW_TYPE.INTAKE_TRIAGE;
    const localType = getIncidentType(data.episodeTypeId);
    
    return {
        ...data,
        fields,
        type: incidentType?.title || localType.tytle,
        injuryDate: dateValue(data.injuryDate),
        $isReportOnly,
        incidentType: {
            ...incidentType,
            abbr: localType.abbr
        },
        incidentTypeId: incidentType?.id,
        $incidentType: localType,
        relatedIncident: transformGet(relatedIncident)
    };
};
export default transformGet;
