import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import { Rules } from 'utils';
import Field from './field';

const LookupLocation = ({ disabled, defaultFilters }) => (
    <Form.Item
        name="locationId"
        label="Employer Location"
        rules={[Rules.required]}
        className="required form-item-locationId"
    >
        <Field
            disabled={disabled}
            defaultFilters={defaultFilters}
        />
    </Form.Item>
);

LookupLocation.propTypes = {
    disabled: PropTypes.bool,
    defaultFilters: PropTypes.object.isRequired,
};

LookupLocation.defaultProps = {
    disabled: false,
};

export { LookupLocation };
