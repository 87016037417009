import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, Fieldset, Select } from 'library';
import { useSelector } from 'react-redux';
import { Rules } from 'utils';
import { INCIDENT_STATE } from 'config';
import IncidentDone from '../../Incident/IncidentDone';
import { InjuryOptions, InjuryQuestions } from './config';
import IncidentContext from '../../Incident/context';

const NoTriageSummaryForm = ({ children }) => {
    const incident = useSelector(state => state.incident);
    const [form] = Form.useForm();
    const { done, update, onDoneSuccess } = useContext(IncidentContext);

    useEffect(() => {
        if (incident.id) {
            form.resetFields();
        }
    }, [incident.id, form]);

    const onFinish = (values) => {
        const item = InjuryOptions.find(it => it.value === values.reportOnlyReason);
        const reportOnlyReason = {
            title: `${item.label}. ${item.description}`,
            value: item.value
        };
        update({
            ...values,
            reportOnlyReason,
            incidentState: INCIDENT_STATE.DONE
        })
            .then(onDoneSuccess);
    };
   
    if (done) {
        return <IncidentDone />;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            className="container"
            initialValues={incident}
        >
            <Spin spinning={!!incident.$loading}>

                <Fieldset title="Report Only Reason">
                    <Form.Item
                        name="reportOnlyReason"
                        className="form-item-reportOnlyReason required"
                        rules={[Rules.required]}
                    >
                        <Select
                            options={InjuryOptions}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            optionRender={({ data }) => (
                                <>
                                    <b>{data.label}</b>
                                    <div>{data.description}</div>
                                </>
                            )}
                            autoFocus
                            listHeight={600}
                            placeholder="Required"
                            popupClassName="popup-report-only-reason"
                        />
                    </Form.Item>
                </Fieldset>
                <Fieldset
                    id="injury-questions"
                    title="Injury Questions"
                    namePrefix="fields"
                    fields={InjuryQuestions}
                    allowClear
                />
                {children}
            </Spin>
        </Form>
    );
};

NoTriageSummaryForm.propTypes = {
    children: PropTypes.element.isRequired
};

export default NoTriageSummaryForm;
