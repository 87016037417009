/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EnterpriseService, ConversationService } from 'services';
import { showError } from 'utils';
import dayjs from 'dayjs';

const initialState = {
    $loading: false,
    $updating: false,
    $error: undefined
};

const getIncident = createAsyncThunk(
    'incident/get',
    (incidentId, { rejectWithValue }) =>
        EnterpriseService.incidents.get(incidentId)
            .catch(err => rejectWithValue({
                message: err.message,
                cause: err.cause
            }))
);

const createAction = async (data, { rejectWithValue, getState }) => {
    const { call: { id: pureCloudConversationId } } = getState();

    const activeConversation = await ConversationService.getActiveConversation();
    data.pureCloudConversationId = activeConversation?.id || pureCloudConversationId;

    return EnterpriseService.incidents.create(data)
        .catch((err) => {
            const error = { message: err.message, cause: err.cause };
            showError(error);
            return rejectWithValue(error);
        });
};

const updateAction = async (data, { rejectWithValue, getState }) => {
    const { call: { id: pureCloudConversationId } } = getState();

    const activeConversation = await ConversationService.getActiveConversation();
    data.pureCloudConversationId = activeConversation?.id || pureCloudConversationId;

    return EnterpriseService.incidents.update(data.id, data)
        .catch((err) => {
            const error = { message: err.message, cause: err.cause };
            showError(error);
            return rejectWithValue(error);
        });
};

const patchAction = (data, { rejectWithValue }) =>
    EnterpriseService.incidents.update(data.id, data)
        .catch((err) => {
            const error = { message: err.message, cause: err.cause };
            showError(error);
            return rejectWithValue(error);
        });

const postIncident = createAsyncThunk(
    'incident/post', createAction
);

const putIncident = createAsyncThunk(
    'incident/put', updateAction
);

const patchIncident = createAsyncThunk(
    'incident/patch', patchAction
);

const incidentSlice = createSlice({
    name: 'incident',
    initialState,
    reducers: {
        setIncident: (state, action) => ({
            key: action.payload.id,
            ...state,
            ...action.payload
        }),
        clear: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIncident.pending, state => ({
                ...state,
                $loading: true
            }))
            .addCase(getIncident.fulfilled, (state, action) => ({
                ...action.payload,
                $loading: false
            }))
            .addCase(getIncident.rejected, (state, { payload }) => {
                state.$error = payload;
                state.$loading = false;
            })
            .addCase(putIncident.pending, (state) => {
                state.$updating = true;
            })
            .addCase(putIncident.fulfilled, (state, action) => ({
                ...action.payload,
                $updatedOn: dayjs().format(''),
                $updating: false
            }))
            .addCase(patchIncident.fulfilled, (state, action) => ({
                ...action.payload,
                $updating: false
            }))
            .addCase(putIncident.rejected, (state, { payload }) => {
                state.$error = payload;
                state.$updating = false;
            });
    }
});

// Action creators are generated for each case reducer function
const { clear, setIncident } = incidentSlice.actions;
export {
    getIncident,
    clear,
    setIncident,
    postIncident,
    putIncident,
    patchIncident
};

export default incidentSlice.reducer;
