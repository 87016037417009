import './clone.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Button, Popover, Radio, Space } from 'library';
import { EnterpriseService } from 'services';
import { DiffOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { showError } from 'utils';
import { INCIDENT_STATE } from 'config';

const PropsToCloneOptions = [
    {
        title: 'Clone without Facility Info',
        value: ['INTAKE'],
    },
    {
        title: 'Clone with Facility Info',
        value: ['INTAKE', 'FACILITY']
    }
];
const CloneIncidentButton = ({ incident }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [cloneProps, setCloneProps] = useState();
    const navigate = useNavigate();
    if (incident.incidentState === INCIDENT_STATE.VOID) {
        return null;
    }

    const onCloneConfirm = () => {
        setLoading(true);
        EnterpriseService.incidents.clone(incident.id, { cloneProps })
            .then((result) => {
                notification.success({ message: `The incident #${incident.claimReferenceNumber} cloned successfully.` });
                navigate(`/incidents/${result.id}/intake`);
            })
            .catch(showError)
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    };
    
    return (
        <Popover
            placement="left"
            overlayClassName="popover-clone"
            title={<><ExclamationCircleFilled />Clone Incident</>}
            trigger="click"
            open={open}
            onOpenChange={setOpen}
            content={(
                <>
                    <div>
                        Are you sure you want to clone Incident
                        {' '}
                        <b>{incident.claimReferenceNumber}</b>?
                    </div>
                    <div>
                        <Radio.Group
                            onChange={e => setCloneProps(e.target.value)}
                            value={cloneProps}
                        >
                            <Space direction="vertical">
                                {PropsToCloneOptions.map(item => (
                                    <Radio
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.title}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </div>
                    <div className="popover-clone-footer">
                        <Button
                            size="small"
                            type="default"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            type="primary"
                            onClick={onCloneConfirm}
                            loading={loading}
                            disabled={!cloneProps}
                        >
                            OK
                        </Button>
                    </div>
                </>
            )}
        >
            <Button
                size="small"
                icon={<DiffOutlined />}
                title="Clone Incident"
                onClick={() => setOpen(!open)}
            />
        </Popover>
    );
};

CloneIncidentButton.propTypes = {
    incident: PropTypes.object.isRequired
};

export default CloneIncidentButton;
