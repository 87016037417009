import { toQueryParams } from 'utils';
import api from '../api';

class NoteService {
    static TRIAGE_TYPE = 1

    static INTAKE_TYPE = 2

    static ALL_TYPE = 3

    static library(visibleIn) {
        const params = toQueryParams({ visibleIn });

        return api.get(`/api/callNotes/library?${params}`)
            .then(({ data }) => data.data)
            .then(data => data.map((i, key) => ({ ...i, key, label: i.text })));
    }

    static save(incidentId, data) {
        return api.post(`/api/v2/incidents/${incidentId}/notes`, { data })
            .then(res => res.data.data);
    }

    static list(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}/notes?perPage=50&page=1`)
            .then(res => res.data.data);
    }
}

export default NoteService;
