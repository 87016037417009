/* eslint-disable no-nested-ternary */
import { getUrlQueryParams, toQueryParams } from 'utils';
import api from '../api';
import transformList from './transform.list';

class EnterpriseFacilitiesService {
    static async listByLocation(locationId, filters) {
        const params = toQueryParams({
            perPage: 30,
            page: 1,
            ...filters
        });
        const { data } = await api.get(`/api/v2/locations/${locationId}/facilities?${params}`);

        return transformList(data?.data);
    }

    static get(id) {
        return api.get(`/api/v2/facilities/${id}`)
            .then(data => data.data);
    }

    static list(params) {
        return api.get(`/api/v2/facilities/?${getUrlQueryParams(params)}`)
            .then(({ data: result }) => ({
                data: transformList(result.data),
                total: result.pagination?.totalResults || result.data.length
            }));
    }

    static networks() {
        return api.get('/api/v2/facility-networks')
            .then(({ data: result }) => result.data);
    }

    static networksByLocationId(locationId) {
        return api.get(`/api/v2/locations/${locationId}/facility-networks`)
            .then(({ data: result }) => result.data);
    }
}

export default EnterpriseFacilitiesService;
