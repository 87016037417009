import './FieldInjurySurvey.scss';
import PropTypes from 'prop-types';
import { TextArea, Form } from 'library';

const FieldInjurySurvey = ({ name, value, onChange, ...rest }) => {
    const form = Form.useFormInstance();
    const data = Form.useWatch(['fields', name], form);
    const lockedValue = data?.lockedValue;

    return (
        <div className="textarea-injury-survey">
            {lockedValue && (
                <div> {lockedValue} </div>
            )}
            <TextArea
                rows={8}
                value={value}
                onChange={onChange}
                {...rest}
            />
        </div>
    );
};

FieldInjurySurvey.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired
};

FieldInjurySurvey.defaultProps = {
    value: undefined,
    onChange: undefined
};

export { FieldInjurySurvey };
