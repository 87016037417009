import './SubHeader.scss';
import PropTypes from 'prop-types';
import { IconNoTriage } from 'assets';

const SubHeader = ({ prefix, title, suffix, children }) => (
    <div className="subheader">
        {(title) && (
            <div className="sub-subheader">
                {prefix}
                {title && <h1>{title}</h1>}
                {suffix}
            </div>
        )}
        {children}
    </div>
);

SubHeader.propTypes = {
    prefix: PropTypes.any,
    title: PropTypes.string,
    children: PropTypes.any,
    suffix: PropTypes.any
};

SubHeader.defaultProps = {
    prefix: <img src={IconNoTriage} alt="" />,
    title: undefined,
    children: null,
    suffix: null
};

export { SubHeader };
