import { INSURANCE_ROLE_ID } from 'config';
import { toQueryParams } from 'utils';
import api from '../api';

import transformContacts from './transformer.contacts';

class EnterpriseLocationService {
    static get(id) {
        return api.get(`/api/v2/locations/${id}`)
            .then(({ data }) => data);
    }

    /* 
    static options({ query, page = 1, perPage = 15, orderBy = 'title', orderDirection = 'asc' }) {
        const params = toQueryParams({
            page,
            perPage,
            query,
            orderBy,
            orderDirection,
        });

        return api.get(`/api/location/elastic?${params}`)
            .then(({ data }) => data);
    } */

    static list(args) {
        const filters = { ...args };
        if (filters.assignedPhoneNumber) {
            filters.assignedPhoneNumber = filters.assignedPhoneNumber.replace(/\D/g, '').substr(1);
        }
        const params = toQueryParams({
            page: 1,
            perPage: 100,
            ...filters,
        });

        return api.get(`/api/v2/locations?${params}`)
            .then(({ data }) => data)
            .then(({ data }) => data);
    }

    static contacts(locationId, filters) {
        const params = toQueryParams(filters);
        return api.get(`/api/v2/locations/${locationId}/contacts?${params}`);
    }

    static externalContacts(locationId, filters) {
        const params = toQueryParams(filters);
        return api.get(`/api/v2/locations/${locationId}/contacts/third-party?${params}`)
            .then(transformContacts);
    }

    static externalLocations(filters) {
        const params = {
            ...filters,
            source: 'GB Pyramid',
        };

        return api.get(`/api/v2/locations/third-party?${toQueryParams(params)}`)
            .then(({ data }) => data)
            .then(({ data }) => {
                if (data.error) {
                    const message = data?.message || '';
                    return Promise.reject(Error(`Invalid Response from ${params.source}: ${data.error} ${message}`));
                }
                return data;
            });
    }

    static insuranceContacts(locationId) {
        const filters = {
            roleType: 1,
            roleId: INSURANCE_ROLE_ID,
        };

        return EnterpriseLocationService.contacts(locationId, filters)
            .then(({ data }) => data
                .map(item => ({
                    ...item,
                    phone: item.work_phone || item.cell_phone || item.home_phone,
                    // eslint-disable-next-line no-nested-ternary
                    phoneType: !!item.work_phone ? 'Work' : (!!item.cell_phone ? 'Mobile' : 'Home')
                }))
                .filter(item => !!item.phone));
    }
}

export default EnterpriseLocationService;
