import { Switch } from 'antd';
import PropTypes from 'prop-types';

const InputBool = ({ value, onChange, ...rest }) => (
    <Switch
        onChange={onChange}
        checked={!!value}
        {...rest}
    />
);

InputBool.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};

InputBool.defaultProps = {
    value: undefined,
    onChange: undefined
};

export { InputBool };
