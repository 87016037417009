/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'library';
import { GenesysContactService, ConversationService } from 'services';
import { transformConversation } from 'services/genesys/conversation/transform';

const initialState = {
    isConnected: false
};

export const fetchGenesysUser = createAsyncThunk(
    'call/fetchGenesysUser',
    phone => GenesysContactService.searchByPhone(phone));
    
export const getActiveConversation = createAsyncThunk(
    'call/getActiveConversation',
    () => ConversationService.getActiveConversation()
);

export const fetchConversation = createAsyncThunk(
    'call/fetchConversation',
    id => ConversationService.get(id)
);

export const transferToRN = createAsyncThunk(
    'call/transferToRn',
    (...args) => ConversationService.transferToRN(...args)
);

export const consultRN = createAsyncThunk(
    'call/consultRN',
    (...args) => ConversationService.consultRN(...args) 
);

export const transferToPhone = createAsyncThunk(
    'call/transferToPhone',
    (...args) => ConversationService.transferToPhone(...args)
);

export const inviteExternal = createAsyncThunk(
    'call/inviteExternal',
    (...args) => ConversationService.inviteExternal(...args)
);

export const inviteInternal = createAsyncThunk(
    'call/inviteInternal',
    (...args) => ConversationService.inviteInternal(...args)
);

export const transferToInternalInterpreter = createAsyncThunk(
    'call/transferToInternalInterpreter',
    (...args) => ConversationService.transferToInternalInterpreter(...args)
);

// eslint-disable-next-line consistent-return
const conversationReducerFn = (state, data) => {
    if (data.payload?.id && data.payload?.id !== state.id) {
        return data.payload;
    }
};

const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        transformCall: (state, action) => transformConversation(action.payload),
        clear: () => ({
            isConnected: false,
        }),
        connect: () => ({
            isConnected: true,
        }),
        disconnect: () => ({
            isConnected: false,
        }),
        clearIncidentId: (state) => {
            state.incidentId = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConversation.fulfilled, conversationReducerFn)
            .addCase(getActiveConversation.fulfilled, conversationReducerFn)
            .addCase(transferToRN.fulfilled, conversationReducerFn)
            .addCase(consultRN.fulfilled, conversationReducerFn)
            .addCase(transferToPhone.fulfilled, conversationReducerFn)
            .addCase(inviteExternal.fulfilled, conversationReducerFn)
            .addCase(inviteInternal.fulfilled, conversationReducerFn)
            .addCase(transferToInternalInterpreter.fulfilled, conversationReducerFn)
            .addCase(fetchGenesysUser.pending, (state) => {
                state.user = { loading: true };
            })
            .addCase(fetchGenesysUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })
            .addCase(fetchGenesysUser.rejected, (state, { error }) => {
                message.error(error.message);
                state.user = { loading: false };
            });
    }
});

// Action creators are generated for each case reducer function
const { transformCall, clearIncidentId, update, connect, disconnect, clear } = callSlice.actions;

export {
    transformCall, update, clear,
    connect, disconnect, clearIncidentId
};

export default callSlice.reducer;
