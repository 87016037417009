import { StarOutlined, PlusSquareFilled } from '@ant-design/icons';

import { 
    renderFacilityDistance,
    renderType
 } from '../../components';

export default ({ preferredIds }) => ([
    {
        title: '',
        dataIndex: 'id',
        width: 50,
        fixed: true,
        render: value => (preferredIds.includes(value) && <StarOutlined />),
    },
    {
        title: 'ER',
        dataIndex: 'type',
        width: 50,
        render: renderType,
    },
    {
        title: 'Facility Name',
        dataIndex: 'name',
        width: 300,
        render: value => (
            <div className="with-icon">
                <PlusSquareFilled />
                {value}
            </div>
        )
    },
    {
        title: 'Proximity',
        dataIndex: 'distance',
        render: renderFacilityDistance
    },
    {
        title: 'Address',
        dataIndex: 'address',
        width: 200,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        width: 100,
    },
    {
        title: 'Fax',
        dataIndex: 'fax',
        width: 100,
    },
    {
        title: 'City',
        dataIndex: 'city',
    },
    {
        title: 'State',
        dataIndex: 'state',
    },
    {
        title: 'Zip',
        dataIndex: 'zip',
    },
]);
