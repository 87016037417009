import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnTypes } from 'library';

import CloneIncidentButton from './buttons/clone';

const renderIncidentTypeId = (_, { $incidentType, clonedFromId }) => (
    <>
        {clonedFromId && (
            <div
                className="cloned-from"
                title={`Cloned from Incident #${clonedFromId}`}
            />
        )}
        <div
            className={`incident-type-icon incident-type-icon-${$incidentType?.abbr}`}
            title={$incidentType.title}
        />
    </>
);

const IncidentListConfig = [
    {
        title: 'ID',
        key: 'refs',
        type: ColumnTypes.string,
        filter: true,
        sort: true,
        fixed: true,
        width: 100,
        render: (val, row) => (
            <Link to={`/incidents/${row.id}/preview`}>
                {row.claimReferenceNumber}
            </Link>
        ),
        sortBy: 'id',
        defaultSortOrder: 'descend',
    },
    {
        title: 'Type',
        key: 'episodeTypeId',
        type: ColumnTypes.string,
        sort: false,
        width: 45,
        filter: false,
        render: renderIncidentTypeId
    },
    {
        title: 'Claimant',
        key: 'claimantFirstLastName',
        type: ColumnTypes.string,
        filter: true,
        sort: true,
        width: 160,
    },
    {
        title: 'Employer Location',
        key: 'employerLocationName',
        type: ColumnTypes.string,
        filter: true,
        sort: true,
        width: 360,
    },
    {
        title: 'Incident Date',
        key: 'injuryDate',
        type: ColumnTypes.dateExact,
        filter: true,
        sort: true,
        width: 120,
        // defaultFilteredValue: ['BETWEENDATE_EXACT', dayjs().subtract(1, 'year'), dayjs()],
    },
    {
        title: 'Cloned From',
        key: 'clonedFromId',
        type: ColumnTypes.string,
        filter: true,
        sort: true,
        width: 80,
        render: val => !!val && (
            <Link to={`/incidents/${val}/intake`}>
                {val}
            </Link>
        )
    },
    {
        title: '',
        fixed: 'right',
        key: 'createdDate',
        type: ColumnTypes.string,
        filter: false,
        sort: false,
        width: 40,
        render: (_, row) => (
            <CloneIncidentButton
                incident={row}
            />
        ),
        responsive: ['md'],
    },
];

export default IncidentListConfig;
