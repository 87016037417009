import './Summary.scss';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PicLeftOutlined, PicRightOutlined } from '@ant-design/icons';
import Context from 'layout/Grid/context';
import Notes from '../Notes';
import Distributions from './Distributions';
import EmployerContacts from './EmployerContacts';
import Facility from './Facility';
import Employer from './Employer';
import General from './General';

const IncidentSummary = ({ noteType }) => {
    const incident = useSelector(state => state.incident);
    const { expanded, setExpanded } = useContext(Context);

    const incidentTitle = incident.$incidentType?.title || '';
    const incidentAbbr = incident.$incidentType?.abbr;
    const Icon = expanded ? PicLeftOutlined : PicRightOutlined;

    return (
        <div className={`panel incident-summary incident-summary-${incidentAbbr}`}>
            <div className="panel-header">
                <div className={`incident-type-icon incident-type-icon-${incidentAbbr}`} />
                <div>{incidentTitle} Incident </div>
                <div className="panel-expand-icon">
                    <Icon onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            <div className={`panel-body ${expanded && 'panel-body-expaned'}`}>
                <div data-panel-body>
                    <General />
                    <Employer />
                    <Notes type={noteType} />
                </div>
                {expanded && (
                    <div data-panel-body>
                        <EmployerContacts />
                        <Facility />
                        <Distributions />
                    </div>
                )}
            </div>
        </div>
    );
};

IncidentSummary.propTypes = {
    noteType: PropTypes.number.isRequired
};

export default IncidentSummary;
