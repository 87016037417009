/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Form } from 'library';
import { SearchOutlined } from '@ant-design/icons';
import configs from './configs';
import LookupClaimant from './LookupClaimant';

const LookupsClaimant = ({ disabled }) => {
    const { contactSources } = useSelector(state => state.location); 
    
    return contactSources?.map((source) => {
        const sourceConfig = configs[source];
        if (!sourceConfig) {
            return null;
        }
        return (
            <div
                className={`directive ${sourceConfig.className }`}
                key={source}
            >
                <label>
                    <SearchOutlined />
                    {sourceConfig.description}
                </label>
                <Form.Item
                    name={['thirdParty', 'contact']}
                    noStyle
                >
                    <LookupClaimant
                        config={sourceConfig}
                    >
                        <Button
                            type="primary"
                            title={`${sourceConfig.title} Claimant Lookup`}
                            disabled={disabled}
                            block
                        >
                            Claimant Lookup
                        </Button>
                    </LookupClaimant>
                
                </Form.Item>
            </div>
        );
    });
};

LookupsClaimant.propTypes = {
    disabled: PropTypes.bool.isRequired
};

export { LookupsClaimant };
