import PropTypes from 'prop-types';
import { Input } from 'antd';

const InputText = ({ value, onChange, ...rest }) => {
    const onBeforeChange = ({ target: { value: val } }) => {
        onChange(val ? val.toString() : undefined);
    };

    return (
        <Input
            onChange={onBeforeChange}
            value={value}
            allowClear
            spellCheck
            {...rest}
        />
    );
};

InputText.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};

InputText.defaultProps = {
    value: undefined,
    onChange: undefined
}; 

export { InputText };
