const uniqId = Math.random().toString().slice(2, 12);

export const InjuryOptions = [
    {
        value: `1_${uniqId }`,
        label: 'Employee Not Present',
        description: 'Employee was not present. Advised to call us when employee is available.',
    },
    {
        value: `2_${uniqId }`,
        label: 'Declined Triage',
        description: 'Does not want to speak to a triage person or seek medical treatment at this time. Advised to call us back if their status changes.',
    }, {
        value: `3_${uniqId }`,
        label: 'Facility Referral Only',
        description: 'Employee requested referral to treatment facility.',
    },
    {
        value: `4_${uniqId }`,
        label: 'Previously Treated',
        description: 'Treated Prior to contacting Injury Hotline.',
    },
    {
        value: `5_${uniqId }`,
        label: 'Incomplete',
        description: 'Requires additional information to complete.Save and Send.',
    },
    {
        value: `6_${uniqId }`,
        label: 'Hangup/Lost Call',
        description: 'Caller/Employee hung up or call dropped.Save and Send.',
    },
    {
        value: `7_${uniqId }`,
        label: 'Employee/Other Returned Call',
        description: 'Called the hotline with additional information.Save and Send.',
    },
    {
        value: `8_${uniqId }`,
        label: 'Internal Audit/Report Review',
        description: 'Document report only.',
    },
    {
        value: `9_${uniqId }`,
        label: 'Employer Follow-up',
        description: 'Follow-up call by RN requested by employer.',
    },
    {
        value: `10_${uniqId }`,
        label: 'Intake Only Customer',
        description: 'Customer is report only and does not require nurse triage.',
    },
];

export const InjuryQuestions = [
    { 
        name: 'injury_description', 
        label: 'How did the accident happen?',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_nature_of', 
        label: 'Describe your Medical Complaint',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_object_involved', 
        label: 'Object, Equipment or Substance',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_activity_description', 
        label: 'Activity Employee Doing',
        maxLength: 500,
        type: 'TEXTAREA',
    },
    { 
        name: 'injury_pertinent_medical_history', 
        label: 'Medical History',
        maxLength: 500,
        type: 'TEXTAREA',
    },
];
