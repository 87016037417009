import './CallerId.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { notification, Form, Spin } from 'library';
import { Grid } from 'layout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INTERPRETER_TYPES } from 'config';
import { clearIncidentId, connect as connectConversation, clear as clearConversation } from 'store/call';
import { postIncident, clear as clearIncident } from 'store/incident';
import { EnterpriseService } from 'services';
import { IncidentDuplicatesCallerId } from 'components/IncidentDuplicates';
import { clear as clearLocation } from 'store/location';
import Context from './context';
import IncidentsTable from './Incidents';
import Title from './SubHeader';
import FormCallerId from './Form';
import IntakeWaiting from './Waiting';
import Footer from './Footer';

const CallerIdentification = () => {
    const [form] = Form.useForm();
    const [interpreter, setInterpreter] = useState();
    const dispatch = useDispatch();
    const call = useSelector(state => state.call);
    const navigate = useNavigate();
    const [creating, setCreating] = useState(false);
    const [parent, setParent] = useState();
    const [parentLoading, setParentLoading] = useState(false);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        if (call.isConnected && call.incidentId) {
            notification.info({ message: `Callback for Incident ${call.incidentId}`, duration: 10 });
            navigate(`/incidents/${call.incidentId}/intake`);
            // fixes https://objectsystems.atlassian.net/browse/LGB-468
            dispatch(clearIncidentId());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [call.incidentId]);

    useEffect(() => {
        if (call.toPhone) {
            setParentLoading(true);
            EnterpriseService.locations.list({
                assignedPhoneNumber: call.toPhone,
                orderBy: 'lft',
                orderDirection: 'asc',
                page: 1,
                perPage: 1
            })
                .then(data => (data.length ? data[0] : null))
                .then(setParent)
                .finally(() => setParentLoading(false));
        } else {
            setParent();
        }
    }, [call.toPhone]);

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent?.id, call.fromPhone, form]);

    const onFinish = (values) => {
        setCreating(true);
        const data = {
            interpreter: values.interpreter_id ? INTERPRETER_TYPES.external : undefined,
            ...values,
        };
        dispatch(postIncident(data))
            .unwrap()
            .then((incident) => {
                navigate(`/incidents/${incident.id}/intake`);
                notification.success({ message: 'The Incident was created successfully.' });
            })
            .finally(() => setCreating(false));
    };

    const cleanUpIncident = useCallback(() => {
        dispatch(clearConversation());
        dispatch(clearLocation());
        dispatch(clearIncident());
    }, [dispatch]);

    const startWithoutCall = () => {
        cleanUpIncident();
        dispatch(connectConversation());
        if (form) {
            form.resetFields();
        }
    };
    return (
        <Context.Provider
            value={{
                interpreter,
                setInterpreter,
                creating,
            }}
        >
            <div className="route route-caller-id">
                <Title />
                <Spin
                    spinning={searching}
                    percent="auto"
                    tip="Searching for Duplicates"
                    className="spin-searching"
                >
                    <Grid>
                        <Form
                            form={form}
                            className="container"
                            layout="vertical"
                            initialValues={{
                                claimantLanguageCode: call.languageId || '1',
                                selfReportValue: 1,
                                callerPhone: call.fromPhone,
                                identifiedLocation: parent?.title
                            }}
                            onFinish={onFinish}
                            requiredMark={false}
                        >
                            {call.isConnected ? (
                                <>
                                    <IncidentDuplicatesCallerId setSearching={setSearching} />
                                    <FormCallerId parent={parent} parentLoading={parentLoading} />
                                </>
                            ) : (
                                <IntakeWaiting
                                    startWithoutCall={startWithoutCall}
                                    cleanUpIncident={cleanUpIncident}
                                />
                            )}
                            <Footer />
                        </Form>
                        <IncidentsTable />
                    </Grid>
                </Spin>

            </div>
        </Context.Provider>
    );
};

export default CallerIdentification;
