import './FormItemDirective.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import { Rules } from 'utils';
import { useSelector } from 'react-redux';
import Directive from './Directive';

const FormItemDirective = ({
    required, type, name, label,
    isColored, disabled, states, onFirstCallOnly, ...rest
}) => {
    const incident = useSelector(state => state.incident);
    const location = useSelector(state => state.incident);
    
    const className = `
        form-item directive
        directive-${type} 
        ${required ? 'required' : ''} 
        ${isColored ? 'warning' : 'info'}
        ${disabled ? 'disabled' : ''}
        form-item-directives-${name}
    `;

    const fieldsState = incident.fields?.employer_location_state?.value;
    const employerState = fieldsState || location.state;
    if (states.length && !states.includes(employerState)) {
        return null;
    }

    const callsCount = incident.calls?.length;
    if (callsCount > 1 && !!onFirstCallOnly) {
        return null;
    }

    return (
        <Form.Item
            className={className}
            name={['directives', name]}
            label={<div dangerouslySetInnerHTML={{ __html: label }} />}
            rules={required ? [Rules.required] : []}
        >
            <Directive 
                type={type}
                required={required}
                disabled={disabled}
                {...rest}
            />
        </Form.Item>
    );
};

FormItemDirective.propTypes = {
    required: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    label: PropTypes.string.isRequired,
    isColored: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    states: PropTypes.array.isRequired,
    onFirstCallOnly: PropTypes.number.isRequired
};

FormItemDirective.defaultProps = {
    required: false,
    type: 'TEXT',
    isColored: false,
    value: undefined,
    onChange: undefined,
    disabled: false
};

export { FormItemDirective };
