import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Popover, Form } from 'library';
import { EnterpriseService } from 'services';
import { LookupDropdown } from 'components/lookups/Dropdown';
import IncidentContext from '../../../Incident/context';

const LookupClaimant = ({ config, children }) => {
    const { patch, update } = useContext(IncidentContext);
    const form = Form.useFormInstance();
    const { source, title, className, onSelect } = config;
    const { id: locationId } = useSelector(state => state.location);
    const [open, setOpen] = useState(false);

    const service = filters => EnterpriseService.locations.externalContacts(
        locationId, {
        ...filters,
        page: 1,
        perPage: 500,
        source
    }
    );

    const getFieldsToPost = (fields) => {
        const res = {};
        Object.keys(fields).forEach((key) => {
            const isLocationField = key.startsWith('employer_location_');
            const valueIsEmpty = [null, '', undefined].includes(fields[key].value);
            if (!(isLocationField && valueIsEmpty)) {
                res[key] = fields[key];
            }
        });
        return res;
    };

    const onOk = async (data) => {
        let item = { ...data };
        const { fields, thirdParty } = item;
        const changedFields = getFieldsToPost(fields);
        const postData = { thirdParty: { contact: thirdParty }, fields: changedFields };
        
        if (onSelect) {
            item = await onSelect(item);
            if (item.locationId) {
                postData.locationId = item.locationId;
                return update(postData);
            }
        }
        patch(postData);
        form.setFieldsValue({ fields: changedFields });
        return Promise.resolve();
    };

    const fields = form.getFieldValue('fields');
    const defultFilters = {
        firstName: fields?.firstName?.value || fields.claimant_first_name?.value,
        lastName: fields?.lastName?.value || fields.claimant_last_name?.value,
        ssn: fields.claimant_ssn?.value,
    };

    return (
        <Popover
            content={(
                <LookupDropdown
                    onCancel={() => setOpen(false)}
                    onOk={onOk}
                    setOpen={setOpen}
                    title={`${title} Claimant Lookup`}
                    service={service}
                    defaultFilters={defultFilters}
                    {...config}
                />
            )}
            trigger="click"
            open={open}
            onOpenChange={setOpen}
            overlayClassName={`lookup-popover ${className}`}
            getPopupContainer={trigger => trigger.parentNode.parentNode}
        >
            {children}
        </Popover>
    );
};

LookupClaimant.propTypes = {
    children: PropTypes.element.isRequired,
    config: PropTypes.shape({
        source: PropTypes.string.isRequired,
        sourceType: PropTypes.string.isRequired,
        columns: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        className: PropTypes.string,
        transform: PropTypes.func,
        onSelect: PropTypes.func
    }).isRequired,
    onChange: PropTypes.func
};

LookupClaimant.defaultProps = {
    onChange: undefined
};

export default LookupClaimant;
